import { Flex, Form, Segmented } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import { addCountryStatusOptions } from '../../model/consts/addCountryStatusOptions';
import { TUseCountriesLogic } from '../../model/hooks/useCountriesLogic';

export interface AddCountryModalProps {
  logic: TUseCountriesLogic;
}

export const AddCountryModal: React.FC<AddCountryModalProps> = (props) => {
  const { state, flow } = props.logic;

  return (
    <CustomModal width={560} isOpen={state.isAddCountryModalOpen} onClose={flow.handleOpenAddCountryModal} centered>
      <ModalTitle>
        <span>Добавление страны</span>
      </ModalTitle>

      <Flex vertical gap={6}>
        <Form form={state.addCountryForm} layout='vertical'>
          <FormItem label='Введите название страны' name='name'>
            <Input placeholder='...' />
          </FormItem>
          <FormItem label='Введите код страны' name='countryCode'>
            <Input placeholder='...' />
          </FormItem>
          <FormItem label='Выберите статус' initialValue={false} name='isEnabled'>
            <Segmented options={addCountryStatusOptions} />
          </FormItem>
        </Form>

        <Button block disabled={!state.isSubmitAddCountryBtnDisabled} size='large' onClick={flow.addCountry}>
          Создать
        </Button>
      </Flex>
    </CustomModal>
  );
};
