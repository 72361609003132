import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { ICommission, ICommissionRequest, ICommissionsRequest, ICommissionsResponse } from '../model/types';

export const commissionsApi = createApi({
  reducerPath: 'commissionsApi',
  baseQuery,
  endpoints: (builder) => ({
    getCommissions: builder.query<ICommissionsResponse, ICommissionsRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[commissionId]': params.commissionId,
          'filter[rateId]': params.rateId,
          'filter[rateFrom]': params.rateFrom,
          'filter[rateTo]': params.rateTo,
          'filter[operation]': params.operation,
          'filter[isPercent]': params.isPercent,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/${params.type}`,
          params: updatedParams,
        };
      },
    }),
    getCommissionsCount: builder.query<{ total: number }, ICommissionsRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[commissionId]': params.commissionId,
          'filter[rateId]': params.rateId,
          'filter[rateFrom]': params.rateFrom,
          'filter[rateTo]': params.rateTo,
          'filter[operation]': params.operation,
          'filter[isPercent]': params.isPercent,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/${params.type}/count`,
          params: updatedParams,
        };
      },
    }),
    putCommission: builder.mutation<ICommission, ICommissionRequest>({
      query: ({ panelType, type, commissionId, body }) => ({
        url: `/${panelType}/${type}/${commissionId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useLazyGetCommissionsQuery, usePutCommissionMutation, useLazyGetCommissionsCountQuery } = commissionsApi;
