import { ScheduleTable } from './ScheduleTable/ScheduleTable';
import { Col, Form, Row, Table, TableColumnsType } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { couriersApi } from 'features/CouriersTable';
import { officesApi } from 'features/OfficesTable';
import { useState } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { useAppMessage } from 'utils/messageContext';

import { UserAddOutlined } from '@ant-design/icons';

import styles from './addOffice.module.scss';

export function AddOffice() {
  const [addNewOffice, setAddNewOffice] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [cityId, setCityId] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const [addOffice, addOfficeResponse] = officesApi.useAddOfficeMutation();
  const { showError, showSuccess } = useAppMessage();

  const onFinish = async () => {
    try {
      //TODO add a validation
      await addOffice({
        name,
        address,
        cityId: +cityId,
        countryCode,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  return (
    <>
      <Button onClick={() => setAddNewOffice(true)} className={styles.add_courier_btn}>
        <UserAddOutlined /> Добавить офис
      </Button>

      <CustomModal
        width={660}
        className={styles.modal}
        isOpen={addNewOffice}
        onClose={() => setAddNewOffice(false)}
        centered
        getContainer={document.body} // fixed bug in safari. when u close the modal page is scrolled down
      >
        <ModalTitle>
          <span>Добавить офис</span>
        </ModalTitle>
        <br />
        <Form layout='vertical' className={styles.form} onFinish={onFinish}>
          <FormItem label='Название' className={styles.form_item}>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormItem>
          <FormItem label='Адрес' className={styles.form_item}>
            <Input value={address ?? ''} onChange={(e) => setAddress(e.target.value)} />
          </FormItem>
          <FormItem label='ID города' className={styles.form_item}>
            <Input value={cityId ?? ''} onChange={(e) => setCityId(e.target.value)} />
          </FormItem>

          <FormItem label='Код города' className={styles.form_item}>
            <Input value={countryCode} onChange={(e) => setCountryCode(e.target.value)} />
          </FormItem>

          {/* <FormItem label='Состояние' className={styles.form_item}>
            <CustomSelect
              id={'transaction-statuses'}
              className={styles.net_select}
              placeholder='Выберите состояние'
              // value={transactionStatusMapping[changedValue as keyof typeof transactionStatusMapping]}
              // value={changedValue}
              // onChange={(value) => setChangedValue(value)}
              options={[
                { label: 'активный', value: 'active' },
                { label: 'уволен', value: 'уволен' },
              ]}
              // options={optionsMapper[editCourierId as keyof typeof optionsMapper]?.map((item) => ({
              //   value: item,
              //   label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
              // }))}
              error={false}
            />
          </FormItem> */}

          {/* <FormItem label='Расписание' className={styles.form_item}>
            <ScheduleTable />
          </FormItem> */}

          <Button className={styles.confirm_btn}>Создать</Button>
        </Form>
      </CustomModal>
    </>
  );
}
