import { ITransaction } from 'entities/Transactions';
import { couriersApi } from 'features/CouriersTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export function useCourierLogic() {
  const { id } = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [courierState, setCourierState] = useState<string | null>(null);
  const [courierData, setCourierData] = useState<any | undefined>();

  const [newName, setNewName] = useState<string | undefined>();
  const [newAddress, setNewAddress] = useState<string | undefined>();
  const [newCityId, setNewCityId] = useState<string | undefined>();
  const [newCityName, setNewCityName] = useState<string | undefined>();
  const [newCountryCode, setNewCountryCode] = useState<string | undefined>();
  const [newCountryName, setNewCountryName] = useState<string | undefined>();
  const [editTransactionId, setEditTransactionId] = useState<ITransaction['transactionId'] | null>(null);
  const [disableCourier] = couriersApi.useDisableCourierMutation();
  const [putCourier] = couriersApi.usePutCourierMutation();
  const { data, isLoading, isFetching } = couriersApi.useGetCourierQuery(
    {
      courierId: id ?? 'frontend error',
    },
    { refetchOnMountOrArgChange: true }
  );

  function openChangeStatusWindow() {
    setEditTransactionId(id ?? '');
  }

  async function changeCourierState(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      putCourier({
        body: { state: courierState ?? 'frontend error' },
        courierId: id ? +id : 0,
      });
      setEditTransactionId(null);

      showSuccess('Статус изменен');
      setCourierData({ ...courierData, state: courierState } as ITransaction);
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  async function disableCourierHandler() {
    try {
      await disableCourier({ courierId: id ?? '' }).unwrap();
      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  const confirmChanges = async () => {
    try {
      //TODO успешным респонсом заменить кэш по этому курьеру, а newName и другие выставить в undefined
      await putCourier({
        body: {
          name: newName,
          address: newAddress,
          cityId: newCityId ? +newCityId : undefined,
          cityName: newCityName,
          countryCode: newCountryCode,
          countryName: newCountryName,
        },
        courierId: id ? +id : 0,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  useEffect(() => {
    if (data?.data.state) setCourierState(data?.data.state);
  }, [data?.data.state]);

  useEffect(() => {
    if (data?.data) setCourierData(data.data);
  }, [data?.data]);

  return {
    data: courierData,
    isLoading,
    isFetching,
    openChangeStatusWindow,
    editTransactionId,
    setEditTransactionId,
    changeCourierState,
    courierState,
    setCourierState,
    disableCourierHandler,
    newName,
    setNewName,
    confirmChanges,
    newAddress,
    setNewAddress,
    newCityId,
    setNewCityId,
    newCityName,
    setNewCityName,
    newCountryCode,
    setNewCountryCode,
    newCountryName,
    setNewCountryName,
  };
}
