import { Flex, Popconfirm, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { IComment } from 'entities/Comment';
import React, { useEffect } from 'react';

import { EditOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, UndoOutlined } from '@ant-design/icons';

import { TUseCommentLogic, useCommentLogic } from '../../model/hooks/useCommentLogic';

import styles from './comment.module.scss';

export interface ICommentProps {
  comment: IComment;
}

export const Comment: React.FC<ICommentProps> = (props) => {
  const { flow, state }: TUseCommentLogic = useCommentLogic();
  const { comment } = props;

  useEffect(() => {
    flow.setCommentIdToEdit(comment.userCommentId);
  }, [comment, flow]);

  return (
    <Flex vertical gap={6} className={styles.comment}>
      <Flex gap={9}>
        <Typography.Text className={styles.comment_author}>{comment.authorName}</Typography.Text>
        <Typography.Text>•</Typography.Text>
        <Typography.Text type='secondary'>
          Добавлен {dayjs(comment.createdAt).format('DD.MM.YYYY')}, последнее изменение {dayjs(comment.updatedAt).format('DD.MM.YYYY в HH:mm')}
        </Typography.Text>
      </Flex>

      {state.isEdit ? (
        <TextArea variant='filled' defaultValue={comment.comment} onChange={flow.handleNewCommentChange} />
      ) : (
        <Typography.Text>{comment.comment}</Typography.Text>
      )}

      <Flex gap={9} className={styles.comment_actions}>
        {comment.isEnabled ? (
          <>
            {state.isEdit ? (
              <Flex align='center' gap={6} onClick={flow.handleEditComment} className={styles.comment_actions_action}>
                <CheckCircleOutlined style={{ color: '#52c41a' }} />
                <Typography.Text type='success'>Сохранить</Typography.Text>
              </Flex>
            ) : (
              <EditOutlined onClick={flow.handleIsEditChange} />
            )}

            {state.isEdit ? (
              <Flex align='center' gap={6} onClick={flow.handleIsEditChange} className={styles.comment_actions_action}>
                <CloseCircleOutlined />
                <Typography.Text>Отменить</Typography.Text>
              </Flex>
            ) : (
              <Popconfirm title='Вы уверены что хотите удалить комментарий?' onConfirm={flow.handleDeleteComment}>
                <DeleteOutlined />
              </Popconfirm>
            )}
          </>
        ) : (
          <Popconfirm title='Вы уверены что хотите восстановить комментарий?' onConfirm={() => flow.handleRestoreComment(comment)}>
            <Flex align='center' gap={6} className={styles.comment_actions_action}>
              <UndoOutlined style={{ color: '#007AFF' }} />
              <Typography.Text style={{ color: '#007AFF' }}>Восстановить</Typography.Text>
            </Flex>
          </Popconfirm>
        )}
      </Flex>
    </Flex>
  );
};
