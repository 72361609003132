import { authApi, authSlice } from 'entities/Auth';
import { currenciesApi } from 'entities/Currencies';
import { emailsApi } from 'entities/Emails';
import { finopsApi } from 'entities/Finops';
import { localesApi } from 'entities/Locales';
import { phonesApi } from 'entities/Phones';
import { timeApi } from 'entities/Time';
import { transactionsApi } from 'entities/Transactions';
import { banksApi } from 'features/BanksTable';
import { commissionsApi } from 'features/CommissionsTable';
import { couriersApi } from 'features/CouriersTable';
import { markupTableApi } from 'features/MarkupTable';
import { officesApi } from 'features/OfficesTable';
import { ratesApi } from 'features/RatesTable';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { commentsReducer } from 'widgets/Comments';
import { userApi } from 'widgets/User';
import { usersApi } from 'widgets/Users';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { adminUserApi } from '../../entities/AdminUser';
import { commentApi } from '../../entities/Comment';
import { countryApi } from '../../entities/Country';
import { payMethodApi } from '../../entities/PayMethod';

const combinedReducers = combineReducers({
  auth: authSlice,
  comments: commentsReducer,

  [userApi.reducerPath]: userApi.reducer,
  [timeApi.reducerPath]: timeApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [banksApi.reducerPath]: banksApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [ratesApi.reducerPath]: ratesApi.reducer,
  [emailsApi.reducerPath]: emailsApi.reducer,
  [phonesApi.reducerPath]: phonesApi.reducer,
  [finopsApi.reducerPath]: finopsApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [localesApi.reducerPath]: localesApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [officesApi.reducerPath]: officesApi.reducer,
  [couriersApi.reducerPath]: couriersApi.reducer,
  [payMethodApi.reducerPath]: payMethodApi.reducer,
  [adminUserApi.reducerPath]: adminUserApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [markupTableApi.reducerPath]: markupTableApi.reducer,
  [commissionsApi.reducerPath]: commissionsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
});

export const store = configureStore({
  reducer: (state, action) => combinedReducers(state, action),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      timeApi.middleware,
      authApi.middleware,
      userApi.middleware,
      timeApi.middleware,
      banksApi.middleware,
      usersApi.middleware,
      ratesApi.middleware,
      emailsApi.middleware,
      phonesApi.middleware,
      finopsApi.middleware,
      countryApi.middleware,
      localesApi.middleware,
      commentApi.middleware,
      officesApi.middleware,
      couriersApi.middleware,
      payMethodApi.middleware,
      adminUserApi.middleware,
      currenciesApi.middleware,
      markupTableApi.middleware,
      commissionsApi.middleware,
      transactionsApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
