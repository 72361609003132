import { useGetFinopsQuery, useGetPaySystemsQuery } from 'entities/Finops';
import { useGetTransactionStatusesQuery } from 'entities/Transactions';
import { useContext, useEffect, useState } from 'react';

import { BanksTableContext } from '../ui/BanksTable/BanksTable';

export function useFiltersLogic() {
  const context = useContext(BanksTableContext);

  const [nameFilter, setNameFilter] = useState<string | undefined>();
  const [paySystemFilter, setPaySystemFilter] = useState<string | undefined>();
  const [isEnabledFilter, setIsEnabledFilter] = useState<boolean | undefined>();

  const { data: paySystems } = useGetPaySystemsQuery({});
  const { data: transactionStatuses } = useGetTransactionStatusesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const { data: finops } = useGetFinopsQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const showEnabledBanks = (value: boolean | undefined) => {
    setIsEnabledFilter(value);
  };

  const resetFilters = () => {
    setNameFilter(undefined);
    setPaySystemFilter(undefined);
    setIsEnabledFilter(undefined);

    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getBanks({
      page: context.currentPage,
      perPage: context.perPage,
      paySystem: paySystemFilter,
      name: nameFilter,
      isEnabled: isEnabledFilter,
    });
    context?.getBanksCount({
      page: context.currentPage,
      perPage: context.perPage,
      paySystem: paySystemFilter,
      name: nameFilter,
      isEnabled: isEnabledFilter,
    });
  };

  function getDataWithoutFilters() {
    context?.getBanks({
      page: context.currentPage,
      perPage: context.perPage,
    });
    context?.getBanksCount({
      page: context.currentPage,
      perPage: context.perPage,
    });
  }

  useEffect(() => {
    getDataWithoutFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    finops,
    transactionStatuses,
    isEnabledFilter,
    nameFilter,
    setNameFilter,
    paySystemFilter,
    setPaySystemFilter,
    applyFilters,
    resetFilters,
    paySystems,
    showEnabledBanks,
  };
}
