import { OfficesTable } from 'features/OfficesTable';

import { AddOffice } from '../AddOffice/AddOffice';

import styles from './officesPage.module.scss';

export function OfficesPage() {
  return (
    <>
      <AddOffice />

      <OfficesTable />
    </>
  );
}
