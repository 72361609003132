import { useAppDispatch } from 'app/GlobalRedux/store';
import { useDeleteCommentMutation, useEditCommentMutation, IComment, IGetCommentsResponse, useLazyGetCommentsQuery } from 'entities/Comment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptyStringValueToUndefined } from 'shared/constants';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import * as selectors from '../selectors/commentsSelectors';
import { commentsActions } from '../slices/commentsSlice';

export const useCommentLogic = () => {
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useAppMessage();
  const query = useParams();

  const [commentIdToEdit, setCommentIdToEdit] = useState<string>('');
  const [newComment, setNewComment] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const filterIsEnabled: boolean | undefined = useSelector(selectors.getFilterIsEnabled);
  const filterUpdatedAt: 'ASC' | 'DESC' | undefined = useSelector(selectors.getFilterUpdatedAt);
  const filterAuthorId: string | undefined = useSelector(selectors.getFilterAuthorId);

  const [deleteCommentRequest] = useDeleteCommentMutation();
  const [editCommentRequest] = useEditCommentMutation();
  const [getCommentsRequest] = useLazyGetCommentsQuery();

  const handleGetComments = async () => {
    try {
      const userId: string | undefined = query.id;
      if (!userId) return;

      const result: IGetCommentsResponse = await getCommentsRequest({
        userId,
        authorId: emptyStringValueToUndefined(filterAuthorId),
        updatedAt: filterUpdatedAt,
        isEnabled: filterIsEnabled,
      }).unwrap();

      dispatch(commentsActions.setComments(result.list));
      dispatch(commentsActions.setTotal(result.total));
    } catch (e) {
      // showError(e as IResponseError);
    }
  };

  const handleEditComment = async () => {
    try {
      const userId: string | undefined = query.id;
      if (!userId || !newComment || !commentIdToEdit) return handleIsEditChange();
      await editCommentRequest({ userId, commentId: commentIdToEdit, comment: newComment }).unwrap();
      handleGetComments();
      showSuccess('Комментарий изменён');
      setNewComment('');
      setIsEdit(false);
      setCommentIdToEdit('');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleDeleteComment = async () => {
    try {
      const userId: string | undefined = query.id;
      if (!userId || !commentIdToEdit) return;
      await deleteCommentRequest({ userId, commentId: commentIdToEdit }).unwrap();
      handleGetComments();
      showSuccess('Комментарий удалён');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleIsEditChange = () => {
    setIsEdit((prev) => !prev);

    if (isEdit) {
      setNewComment('');
      setCommentIdToEdit('');
    }
  };

  const handleRestoreComment = async (comment: IComment) => {
    try {
      const userId: string | undefined = query.id;
      if (!userId || !comment) return;
      await editCommentRequest({ userId, commentId: comment.userCommentId, isEnabled: !comment.isEnabled }).unwrap();
      handleGetComments();
      showSuccess('Комментарий восстановлен');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleNewCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  return {
    flow: {
      handleEditComment,
      handleIsEditChange,
      setCommentIdToEdit,
      handleDeleteComment,
      handleRestoreComment,
      handleNewCommentChange,
    },
    state: {
      isEdit,
    },
  };
};

export type TUseCommentLogic = ReturnType<typeof useCommentLogic>;
