import { Flex, Form, Segmented } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import { isEnabledPayMethodOptions } from '../../model/consts/isEnabledPayMethodOptions';
import { isHiddenPayMethodOptions } from '../../model/consts/isHiddenPayMethodOptions';
import { TUsePayMethodsLogic } from '../../model/hooks/usePayMethodsLogic';

export interface AddPayMethodModalProps {
  logic: TUsePayMethodsLogic;
}

export const AddPayMethodModal: React.FC<AddPayMethodModalProps> = (props) => {
  const { flow, state } = props.logic;

  return (
    <CustomModal width={560} isOpen={state.isAddPayMethodModalOpen} onClose={flow.handleIsAddPayMethodModalOpen} centered>
      <ModalTitle>
        <span>Добавить метод</span>
      </ModalTitle>

      <Flex vertical gap={6}>
        <Form layout='vertical' form={state.addPayMethodForm}>
          <FormItem label='Введите название' name='name'>
            <Input placeholder='...' />
          </FormItem>
          <FormItem label='Введите системное название' name='systemName'>
            <Input placeholder='...' />
          </FormItem>
          <FormItem label='Добавьте описание' name='description'>
            <Input placeholder='...' />
          </FormItem>
          <FormItem initialValue={false} label='Статус' name='isEnabled'>
            <Segmented options={isEnabledPayMethodOptions} />
          </FormItem>
          <FormItem initialValue={false} label='Отображение' name='isHidden'>
            <Segmented options={isHiddenPayMethodOptions} />
          </FormItem>
        </Form>
      </Flex>

      <Button size='large' onClick={flow.addPayMethod} disabled={state.isAddPayMethodSubmitBtnDisabled} block>
        Создать
      </Button>
    </CustomModal>
  );
};
