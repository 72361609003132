import { Button as AntButton } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React from 'react';
import { TClassName } from 'shared/types/Types/TClassName';
import { cn } from 'utils/classnames';

import styles from './button.module.scss';

interface Props {
  children: React.ReactNode | string;
  className?: TClassName;
  href?: string;
  onClick?: (e: any) => void;
  icon?: React.ReactNode;
  size?: SizeType;
  disabled?: boolean;
  loading?: boolean;
  htmlType?: 'submit' | 'button' | 'reset';
  block?: boolean;
}

export function Button({ children, className, href, onClick, disabled, loading, htmlType = 'submit', ...props }: Props) {
  return (
    <AntButton
      loading={loading}
      htmlType={htmlType}
      onClick={onClick}
      className={cn(styles.btn, disabled && styles.disabled, className)}
      href={href}
      {...props}
    >
      {children}
    </AntButton>
  );
}
