import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ICountry } from 'entities/Country';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

export const countriesTableColumns: ColumnsType<ICountry> = [
  {
    key: 'countryCode',
    title: 'Код страны',
    dataIndex: 'countryCode',
  },
  {
    key: 'name',
    title: 'Страна',
    dataIndex: 'name',
  },
  {
    key: 'isEnabled',
    title: 'Статус',
    dataIndex: 'isEnabled',
    render: (isEnabled) => (isEnabled ? 'Активна' : 'Не активна'),
  },
  {
    key: 'createdAt',
    title: 'Добавлена',
    dataIndex: 'createdAt',
    render: (date) => dayjs(date).format('YYYY-MM-DD HH:00'),
  },
  {
    title: 'Действия',
    dataIndex: 'btn',
    key: 'btn',
    render: (_, record) => (
      <NavLink to={`${window.location.pathname}/${record.countryCode}`}>
        <Button>Подробнее</Button>
      </NavLink>
    ),
  },
];
