import { Divider, Flex, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { TUseCommentsLogic } from '../../model/hooks/useCommentsLogic';
import styles from '../Comment/comment.module.scss';

export interface INewCommentProps {
  logic: TUseCommentsLogic;
}

export const NewComment: React.FC<INewCommentProps> = (props) => {
  const { flow } = props.logic;

  return (
    <Flex vertical gap={15}>
      <Typography.Text>Новый комментарий</Typography.Text>

      <TextArea variant='filled' onChange={flow.handleNewCommentChange} />

      <Flex align='center' gap={9}>
        <Flex align='center' gap={6} onClick={flow.handleCreateComment} className={styles.comment_actions_action}>
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
          <Typography.Text type='success'>Сохранить</Typography.Text>
        </Flex>

        <Flex align='center' gap={6} onClick={flow.handleIsNewCommentChange} className={styles.comment_actions_action}>
          <CloseCircleOutlined />
          <Typography.Text>Отменить</Typography.Text>
        </Flex>
      </Flex>

      <Divider style={{ margin: '9px' }} />
    </Flex>
  );
};
