export interface ICourier {
  courierId: number;
  countryCode: string;
  countryName: string;
  cityId: number;
  cityName: string;
  name: string;
  nameLocales: [
    {
      localeCode: string;
      l10n: string;
    },
  ];
  address: string;
  addressLocales: [
    {
      localeCode: string;
      l10n: string;
    },
  ];
  schedule: ICourierScheduleItem[];
  state: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICourierScheduleItem {
  begin: string | undefined;
  end: string | undefined;
  pause?: [
    {
      begin: string | null;
      end: string | null;
    },
  ];
  weekDay: `${EWeekDaysMap}`;
}

export type TCourierScheduleItemOnServer = Exclude<ICourierScheduleItem, 'weekDay'>;

export interface ICourierScheduleOnServer {
  monday: TCourierScheduleItemOnServer;
  tuesday: TCourierScheduleItemOnServer;
  wednesday: TCourierScheduleItemOnServer;
  thursday: TCourierScheduleItemOnServer;
  friday: TCourierScheduleItemOnServer;
  saturday: TCourierScheduleItemOnServer;
  sunday: TCourierScheduleItemOnServer;
}

export interface ICouriersResponse {
  total: string;
  list: ICourier[];
}

export interface ICouriersRequest {
  createdAt?: 'ASC' | 'DSC';
  page?: number;
  perPage?: number;
  countryCode?: string;
  cityId?: number;
  name?: string;
  state?: string;
}

export type TAddCourierRequest = Pick<ICourier, 'name' | 'address' | 'cityId' | 'countryCode'>;

export enum EWeekDaysMap {
  monday = 'Понедельник',
  tuesday = 'Вторник',
  wednesday = 'Среда',
  thursday = 'Четверг',
  friday = 'Пятница',
  saturday = 'Суббота',
  sunday = 'Воскресенье',
}

export type TPUTCourierRequest = Partial<ICourier & { schedule: ICourierScheduleOnServer }>;
