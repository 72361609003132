import { IBank } from './types';
import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { banksApi } from '../api/banksApi';

export type DataIndex = keyof IBank;
type OnChange = NonNullable<TableProps<IBank>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useBanksTableLogic() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [editBankId, setEditBankId] = useState<IBank['bankId'] | null>(null);

  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const [currentPage, setCurrentPage] = useState(searchParams.get('pagination') ? Number(searchParams.get('pagination')) : 1);
  const [perPage, setPerPage] = useState(50);

  const { showError, showSuccess } = useAppMessage();

  const [getBanks, getBanksResponse] = banksApi.useLazyGetBanksQuery({ pollingInterval: 60000 });
  const [getBanksCount, getBanksCountResponse] = banksApi.useLazyGetBanksCountQuery();
  const [putBank, putBankResponse] = banksApi.usePutBankMutation();

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 0);

    searchParams.set('pagination', pagination.current?.toString() ?? '1');
    setSearchParams(searchParams);

    setSortedInfo(sorter as Sorts);
  };

  useEffect(() => {
    if (getBanksResponse.isError) showError(getBanksResponse.error as IResponseError);
  }, [getBanksResponse.isError, getBanksResponse.error]);

  return {
    data: getBanksResponse.data,
    setEditBankId,
    editBankId,
    isLoading: getBanksResponse.isLoading,
    isFetching: getBanksResponse.isFetching,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    sortedInfo,
    handleChange,
    transactionsCount: getBanksCountResponse.data?.total,
    getBanks,
    getBanksResponse,
    getBanksCount,
    getBanksCountResponse,
  };
}

export type TBanksTableLogic = ReturnType<typeof useBanksTableLogic>;
