import { Form, TablePaginationConfig } from 'antd';
import { countryApi, ICountry, IGetCountriesParams, IGetCountriesResult } from 'entities/Country';
import { useEffect, useState } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export const useCountriesLogic = () => {
  const { showError, showSuccess } = useAppMessage();
  const [addCountryForm] = Form.useForm();
  const [form] = Form.useForm();

  // Countries state
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Add country state
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] = useState<boolean>(false);
  const newCountryStatus: boolean = Form.useWatch('isEnabled', addCountryForm);
  const newCountryCode: string = Form.useWatch('countryCode', addCountryForm);
  const newCountryName: string = Form.useWatch('name', addCountryForm);

  // Filters state
  const [perPageFilter, setPerPageFilter] = useState<number>(50);
  const [pageFilter, setPageFilter] = useState<number>(1);
  const createdAtFilter = Form.useWatch('createdAt', form);
  const isEnabledFilter = Form.useWatch('isEnabled', form);
  const nameFilter = Form.useWatch('name', form);

  // Countries actions
  const [getCountriesRequest, getCountriesResult] = countryApi.useLazyGetCountriesQuery();
  const [addCountryRequest, addCountryResult] = countryApi.useAddCountryMutation();

  const addCountry = async () => {
    try {
      if (!newCountryName || !newCountryCode) return;

      const result = await addCountryRequest({
        name: newCountryName,
        isEnabled: newCountryStatus,
        countryCode: newCountryCode,
      }).unwrap();

      setTotal(result.total);
      setCountries(result.list);
      setIsAddCountryModalOpen(false);
      addCountryForm.resetFields();
      showSuccess('Страна успешно добавлена');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const getCountries = async (params?: IGetCountriesParams) => {
    try {
      const result: IGetCountriesResult = await getCountriesRequest({
        name: nameFilter,
        page: pageFilter,
        perPage: perPageFilter,
        isEnabled: isEnabledFilter === '' ? undefined : isEnabledFilter,
        createdAt: createdAtFilter?.toISOString(),
        ...params,
      }).unwrap();

      setTotal(result?.total ?? 0);
      setCountries(result?.list ?? []);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleApplyFilters = () => {
    getCountries({});
  };

  const handleResetFilters = () => {
    form.resetFields();
    setPageFilter(1);
    setPerPageFilter(50);
    getCountries({ page: 1, perPage: 50 });
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPageFilter(pagination.current as number);
    setPerPageFilter(pagination.pageSize as number);
  };

  const handleOpenAddCountryModal = () => {
    setIsAddCountryModalOpen((prev) => !prev);
  };

  useEffect(() => {
    getCountries({});
  }, [pageFilter]);

  return {
    flow: {
      addCountry,
      setPageFilter,
      setPerPageFilter,
      handleTableChange,
      handleResetFilters,
      handleApplyFilters,
      handleOpenAddCountryModal,
    },
    state: {
      form,
      total,
      countries,
      pageFilter,
      perPageFilter,
      newCountryCode,
      newCountryName,
      addCountryForm,
      newCountryStatus,
      isAddCountryModalOpen,
      isLoading: getCountriesResult.isLoading || addCountryResult.isLoading,
      isSubmitBtnDisabled: false,
      isSubmitAddCountryBtnDisabled: !!newCountryName && !!newCountryCode,
    },
  };
};

export type TUseCountriesLogic = ReturnType<typeof useCountriesLogic>;
