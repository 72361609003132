import {
  couriersApi,
  EWeekDaysMap,
  ICourier,
  ICourierScheduleItem,
  ICourierScheduleOnServer,
  TPUTCourierRequest,
} from 'features/CouriersTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export function useCourierScheduleLogic() {
  const { id } = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [courierData, setCourierData] = useState<ICourier | undefined>();

  const [putCourier] = couriersApi.usePutCourierMutation();
  const { data, isLoading, isFetching } = couriersApi.useGetCourierQuery({
    courierId: id ?? 'frontend error',
  });

  const saveSchedule = async () => {
    if (!courierData) return;

    const newCourierData = structuredClone(courierData);

    function getEnumKeyByValue(value: ICourierScheduleItem['weekDay']): keyof EWeekDaysMap {
      return (
        (Object.keys(EWeekDaysMap).find((key) => EWeekDaysMap[key as keyof typeof EWeekDaysMap] === value) as keyof EWeekDaysMap) ?? 'monday'
      );
    }

    const transformScheduleForServer = newCourierData.schedule.reduce((acc: any, curr: any) => {
      acc[getEnumKeyByValue(curr.weekDay)] = {
        begin: curr.begin,
        end: curr.end,
        pause: curr.pause,
      };

      return acc;
    }, {} as ICourierScheduleOnServer);

    newCourierData.schedule = transformScheduleForServer;

    try {
      await putCourier({
        body: newCourierData as TPUTCourierRequest,
        courierId: id ? +id : 0,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const changeScheduleTime = (value: string | undefined, index: number, type: 'begin' | 'end') => {
    if (!courierData) return;

    const newData = structuredClone(courierData);

    if (!newData.schedule) return;

    newData.schedule[index][type] = value;

    setCourierData(newData);
  };

  const deleteBreak = (itemIndex: number, removeIndex: number) => {
    if (!courierData) return;

    const newData = structuredClone(courierData);

    if (!newData.schedule) return;

    newData.schedule[itemIndex].pause?.splice(removeIndex, 1);

    setCourierData(newData);
  };

  const changeBreak = (weekDayIndex: number, pauseIndex: number, value: { begin: string | null; end: string | null }) => {
    if (!courierData) return;

    const newData = structuredClone(courierData);

    if (!newData.schedule || !newData.schedule[weekDayIndex].pause) return;

    newData.schedule[weekDayIndex].pause[pauseIndex] = value;

    setCourierData(newData);
  };

  const addNewBreak = (weekDayIndex: number) => {
    if (!courierData) return;

    const newData = structuredClone(courierData);

    if (!newData.schedule) return;

    if (newData.schedule[weekDayIndex].pause) {
      newData.schedule[weekDayIndex].pause.push({ begin: null, end: null });
    } else {
      newData.schedule[weekDayIndex].pause = [{ begin: null, end: null }];
    }

    setCourierData(newData);
  };

  useEffect(() => {
    if (data?.data) setCourierData(data.data);
  }, [data?.data]);

  return { changeScheduleTime, changeBreak, deleteBreak, addNewBreak, courierData, saveSchedule };
}
