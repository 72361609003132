import { Card, Divider, Flex, Table, Typography } from 'antd';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';

import { payMethodsTableColumns } from '../../model/consts/payMethodsTableColumns';
import { usePayMethodsLogic } from '../../model/hooks/usePayMethodsLogic';
import { AddPayMethodModal } from '../AddPayMethodModal/AddPayMethodModal';

export const PayMethods = () => {
  const { flow, state } = usePayMethodsLogic();

  return (
    <React.Fragment>
      <Card style={{ marginBottom: 21 }}>
        <Flex align='center' justify='space-between'>
          <Flex vertical>
            <Typography.Title level={3} style={{ marginBottom: 3 }} children='Платежные методы' />
            <Typography.Text type='secondary' children={`Номенклатура`} />
          </Flex>
          <Button onClick={flow.handleIsAddPayMethodModalOpen}>Добавить метод +</Button>
        </Flex>

        <Divider />
        <Typography.Title level={4} style={{ marginBottom: 24 }} children={`Найдено ${state.totalPayMethodsCount} платежных методов`} />

        <Table
          scroll={{ x: 'scroll' }}
          rowKey={(row) => row.name}
          columns={payMethodsTableColumns}
          loading={state.isLoading}
          dataSource={state.payMethods}
          pagination={false}
        />
      </Card>

      <AddPayMethodModal logic={{ flow, state }} />
    </React.Fragment>
  );
};
