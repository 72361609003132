import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IPayMethod } from 'entities/PayMethod';

export const countryPayMethodsTableColumns: ColumnsType<IPayMethod> = [
  {
    key: 'payMethodId',
    title: 'ID',
    dataIndex: 'payMethodId',
  },
  {
    key: 'name',
    title: 'Название',
    dataIndex: 'name',
  },
  {
    key: 'systemName',
    title: 'Системное название',
    dataIndex: 'systemName',
  },
  {
    key: 'isEnabled',
    title: 'Статус',
    dataIndex: 'isEnabled',
    render: (isEnabled) => (isEnabled ? 'Активен' : 'Не активен'),
  },
  {
    key: 'isHidden',
    title: 'Отображение',
    dataIndex: 'isHidden',
    render: (isHidden) => (isHidden ? 'Виден' : 'Скрыт'),
  },
  {
    key: 'createdAt',
    title: 'Добавлен',
    dataIndex: 'createdAt',
    render: (date) => dayjs(date).format('YYYY-MM-DD HH:00'),
  },
];
