'use client';

import {
  IGetCommentDetailsResponse,
  IGetCommentDetailsRequest,
  ICreateCommentResponse,
  IDeleteCommentResponse,
  IDeleteCommentRequest,
  ICreateCommentRequest,
  IEditCommentResponse,
  IGetCommentsResponse,
  IEditCommentRequest,
  IGetCommentsRequest,
} from './commentApiTypes';
import { baseQuery } from 'shared/api';

import { createApi } from '@reduxjs/toolkit/query/react';

export const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery,
  endpoints: (builder) => ({
    getComments: builder.query<IGetCommentsResponse, IGetCommentsRequest>({
      query: (params) => {
        const updatedParams: Record<string, string | number | boolean | undefined> = {
          'filter[authorId]': params?.authorId,
          'filter[isEnabled]': params?.isEnabled,
          'paging[page]': params?.page,
          'paging[perPage]': params?.perPage,
          'sorting[updatedAt]': params?.updatedAt,
        };

        return {
          url: `/wallet/users/${params.userId}/comments`,
          params: updatedParams,
        };
      },
    }),
    createComment: builder.mutation<ICreateCommentResponse, ICreateCommentRequest>({
      query: ({ userId, ...body }) => ({
        url: `/wallet/users/${userId}/comments`,
        method: 'POST',
        body,
      }),
    }),
    getCommentDetails: builder.query<IGetCommentDetailsResponse, IGetCommentDetailsRequest>({
      query: (params) => ({
        url: `/wallet/users/${params.userId}/comments/${params.commentId}`,
      }),
    }),
    editComment: builder.mutation<IEditCommentResponse, IEditCommentRequest>({
      query: ({ userId, commentId, ...body }) => ({
        url: `/wallet/users/${userId}/comments/${commentId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteComment: builder.mutation<IDeleteCommentResponse, IDeleteCommentRequest>({
      query: ({ userId, commentId, ...body }) => ({
        url: `/wallet/users/${userId}/comments/${commentId}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useEditCommentMutation,
  useLazyGetCommentsQuery,
  useDeleteCommentMutation,
  useCreateCommentMutation,
  useGetCommentDetailsQuery,
  useLazyGetCommentDetailsQuery,
} = commentApi;
