export interface IOffice {
  officeId: number;
  countryCode: string;
  countryName: string;
  cityId: number;
  cityName: string;
  name: string;
  nameLocales: [
    {
      localeCode: string;
      l10n: string;
    },
  ];
  address: string;
  addressLocales: [
    {
      localeCode: string;
      l10n: string;
    },
  ];
  schedule: IOfficeScheduleItem[];
  state: string;
  createdAt: string;
  updatedAt: string;
}

export interface IOfficeScheduleItem {
  begin: string | undefined;
  end: string | undefined;
  pause?: [
    {
      begin: string | null;
      end: string | null;
    },
  ];
  weekDay: `${EWeekDaysMap}`;
}

export type TOfficeScheduleItemOnServer = Exclude<IOfficeScheduleItem, 'weekDay'>;

export interface IOfficeScheduleOnServer {
  monday: TOfficeScheduleItemOnServer;
  tuesday: TOfficeScheduleItemOnServer;
  wednesday: TOfficeScheduleItemOnServer;
  thursday: TOfficeScheduleItemOnServer;
  friday: TOfficeScheduleItemOnServer;
  saturday: TOfficeScheduleItemOnServer;
  sunday: TOfficeScheduleItemOnServer;
}

export interface IOfficeResponse {
  total: string;
  list: IOffice[];
}

export interface IOfficeRequest {
  createdAt?: 'ASC' | 'DSC';
  page?: number;
  perPage?: number;
  countryCode?: string;
  cityId?: number;
  name?: string;
  state?: string;
}

export type TAddOfficeRequest = Pick<IOffice, 'name' | 'address' | 'cityId' | 'countryCode'>;

export enum EWeekDaysMap {
  monday = 'Понедельник',
  tuesday = 'Вторник',
  wednesday = 'Среда',
  thursday = 'Четверг',
  friday = 'Пятница',
  saturday = 'Суббота',
  sunday = 'Воскресенье',
}

export type TPUTOfficeRequest = Partial<IOffice & { schedule: IOfficeScheduleOnServer }>;
