import { Card, Col, Form, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyCodeSelector } from 'features/CurrencyCodeSelector';
import { TransactionTypeSelector } from 'features/TransactionTypeSelector';
import { emptyStringToUndefined, operationTypeMapping, paySystemMapping, transactionStatusMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

import { useFiltersLogic } from '../../model/useFiltersLogic';

import styles from './filters.module.scss';

export function Filters() {
  const logic = useFiltersLogic();

  return (
    <Card title='Фильтр'>
      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            {/* <FormItem label='ID города' className={styles.form_item}>
              <Input value={logic.cityIdFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setCityIdFilter)} />
            </FormItem> */}
            <FormItem label='Имя' className={styles.form_item}>
              <Input value={logic.nameFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setNameFilter)} />
            </FormItem>
          </Col>
          {/* //TODO нужна ручка для списка статусов по курьерам */}
          <Col span={8}>
            <FormItem label='Статус' className={styles.form_item}>
              <CustomSelect
                id={'state-filter'}
                className={styles.net_select}
                value={logic.stateFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setStateFilter)}
                emptyOption={{ value: '', label: 'Все' }}
                options={logic.transactionStatuses?.list?.map((item) => ({
                  value: item,
                  label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
                }))}
                error={false}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>

      <div className={styles.filters_btns}>
        <Button onClick={logic.applyFilters}>Применить</Button>
        <Button onClick={logic.resetFilters}>Отменить</Button>
      </div>
    </Card>
  );
}
