import { setAuthority } from './authSlice';
import { useAppDispatch, useAppSelector } from 'app/GlobalRedux/store';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { useSignOutMutation } from '../api/authApi';

export const ACCESS_TOKEN = 'accessToken';

export const useAuth = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logout] = useSignOutMutation();

  const setToken = (token: string) => {
    Cookies.set(ACCESS_TOKEN, token);
    dispatch(setAuthority({ isLoggedIn: Boolean(Cookies.get(ACCESS_TOKEN)) }));
  };

  const signOut = async (status?: number) => {
    try {
      if (status === undefined) await logout({});
      Cookies.remove(ACCESS_TOKEN);
      dispatch(setAuthority({ isLoggedIn: false }));
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return {
    signOut,
    setToken,
    isLoggedIn,
  };
};
