import { CouriersTable } from 'features/CouriersTable';

import { AddCourier } from '../AddCourier/AddCourier';

import styles from './couriers.module.scss';

export function CouriersPage() {
  return (
    <>
      <AddCourier />

      <CouriersTable />
    </>
  );
}
