import { useGetFinopsQuery } from 'entities/Finops';
import { useGetTransactionStatusesQuery } from 'entities/Transactions';
import { useContext, useEffect, useState } from 'react';

import { CouriersTableContext } from '../ui/OfficesTable/OfficesTable';

export function useFiltersLogic() {
  const context = useContext(CouriersTableContext);

  const [cityIdFilter, setCityIdFilter] = useState<string | undefined>();
  const [countryCodeFilter, setCountryCodeFilter] = useState<string | undefined>();
  const [nameFilter, setNameFilter] = useState<string | undefined>();
  const [stateFilter, setStateFilter] = useState<string | undefined>();

  const { data: transactionStatuses } = useGetTransactionStatusesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const { data: finops } = useGetFinopsQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const resetFilters = () => {
    setCityIdFilter(undefined);
    setNameFilter(undefined);
    setStateFilter(undefined);
    setCountryCodeFilter(undefined);

    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getOffices({
      page: context.currentPage,
      perPage: context.perPage,
      cityId: cityIdFilter ? +cityIdFilter : undefined,
      countryCode: countryCodeFilter,
      name: nameFilter,
      state: stateFilter,
    });
    context?.getOfficesCount({
      page: context.currentPage,
      perPage: context.perPage,
      cityId: cityIdFilter ? +cityIdFilter : undefined,
      countryCode: countryCodeFilter,
      name: nameFilter,
      state: stateFilter,
    });
  };

  function getDataWithoutFilters() {
    context?.getOffices({
      page: context.currentPage,
      perPage: context.perPage,
    });
    context?.getOfficesCount({
      page: context.currentPage,
      perPage: context.perPage,
    });
  }

  useEffect(() => {
    getDataWithoutFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    finops,
    transactionStatuses,
    cityIdFilter,
    setCityIdFilter,
    countryCodeFilter,
    setCountryCodeFilter,
    nameFilter,
    setNameFilter,
    stateFilter,
    setStateFilter,
    applyFilters,
    resetFilters,
  };
}
