import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { IUser } from 'widgets/User';

import { useLazyGetUsersCountQuery, useLazyGetUsersQuery } from '../api/usersApi';

type OnChange = NonNullable<TableProps<IUser>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useUsersLogic() {
  const { showError } = useAppMessage();

  const [filteredData, setFilteredData] = useState<IUser[] | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [perPage, setPerPage] = useState(50);

  const [getUsersCount, getUsersCountResponse] = useLazyGetUsersCountQuery();
  const [getUsers, getUsersResponse] = useLazyGetUsersQuery();

  const findAllEnabled = (array: IUser['wallets']) => {
    return array.reduce(
      (acc: IUser['wallets'], curr) => {
        if (curr.isEnabled) acc.push(curr);
        return acc;
      },
      [] as unknown as IUser['wallets']
    );
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 0);
    setSortedInfo(sorter as Sorts);
  };

  useEffect(() => {
    if (getUsersResponse.isError) showError(getUsersResponse.error as IResponseError);
  }, [getUsersResponse.isError, getUsersResponse.error]);

  useEffect(() => {
    if (getUsersResponse.isLoading === false && getUsersResponse.isFetching === false && getUsersResponse.data) {
      setFilteredData(getUsersResponse.data.list);
    }
  }, [getUsersResponse.isLoading, getUsersResponse.isFetching]);

  return {
    filteredData,
    isLoading: getUsersResponse.isLoading,
    isFetching: getUsersResponse.isFetching,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    sortedInfo,
    handleChange,
    usersCount: getUsersCountResponse.data,
    findAllEnabled,
    getUsers,
    getUsersResponse,
    getUsersCount,
    getUsersCountResponse,
  };
}

export type TUsersLogic = ReturnType<typeof useUsersLogic>;
