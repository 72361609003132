import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { IPayMethod } from 'entities/PayMethod';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

export const payMethodsTableColumns: ColumnsType<IPayMethod> = [
  {
    key: 'payMethodId',
    title: 'ID',
    dataIndex: 'payMethodId',
  },
  {
    key: 'name',
    title: 'Название',
    dataIndex: 'name',
  },
  {
    key: 'systemName',
    title: 'Системное название',
    dataIndex: 'systemName',
  },
  {
    key: 'isEnabled',
    title: 'Статус',
    dataIndex: 'isEnabled',
    render: (isEnabled) => (isEnabled ? 'Активен' : 'Не активен'),
  },
  {
    key: 'isHidden',
    title: 'Отображение',
    dataIndex: 'isHidden',
    render: (isHidden) => (isHidden ? 'Скрыт' : 'Виден'),
  },
  {
    key: 'createdAt',
    title: 'Добавлен',
    dataIndex: 'createdAt',
    render: (date) => dayjs(date).format('YYYY-MM-DD HH:00'),
  },
  {
    key: 'btn',
    title: 'Действия',
    dataIndex: 'createdAt',
    render: (_, record) => (
      <NavLink to={`${window.location.pathname}/${record.payMethodId}`}>
        <Button>Подробнее</Button>
      </NavLink>
    ),
  },
];
