import { IOffice } from './types';
import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { officesApi } from '../api/officesApi';

export type DataIndex = keyof IOffice;
type OnChange = NonNullable<TableProps<IOffice>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useOfficesTableLogic() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [editOfficeId, setEditOfficeId] = useState<IOffice['officeId'] | null>(null);
  const [courierState, setCourierState] = useState<string | null>(null);

  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const [currentPage, setCurrentPage] = useState(searchParams.get('pagination') ? Number(searchParams.get('pagination')) : 1);
  const [perPage, setPerPage] = useState(50);

  const { showError, showSuccess } = useAppMessage();

  const [getOffices, getOfficesResponse] = officesApi.useLazyGetOfficesQuery({ pollingInterval: 60000 });
  const [getOfficesCount, getOfficesCountResponse] = officesApi.useLazyGetOfficesCountQuery();
  const [putOffice, putOfficeResponse] = officesApi.usePutOfficeMutation();

  async function changeCourierState(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await putOffice({
        body: { state: courierState ?? 'frontend error' },
        officeId: editOfficeId ?? 0,
      }).unwrap();

      setEditOfficeId(null);
      showSuccess('Статус изменен');
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 0);

    searchParams.set('pagination', pagination.current?.toString() ?? '1');
    setSearchParams(searchParams);

    setSortedInfo(sorter as Sorts);
  };

  useEffect(() => {
    if (getOfficesResponse.isError) showError(getOfficesResponse.error as IResponseError);
  }, [getOfficesResponse.isError, getOfficesResponse.error]);

  return {
    setCourierState,
    courierState,
    data: getOfficesResponse.data,
    setEditOfficeId,
    editOfficeId,
    isLoading: getOfficesResponse.isLoading,
    isFetching: getOfficesResponse.isFetching,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    sortedInfo,
    handleChange,
    transactionsCount: getOfficesCountResponse.data?.total,
    getOffices,
    getOfficesResponse,
    getOfficesCount,
    getOfficesCountResponse,
    changeCourierState,
  };
}

export type TFinOperationsTableLogic = ReturnType<typeof useOfficesTableLogic>;
