import { TabsProps } from 'antd/lib';
import { CommissionsTable } from 'features/CommissionsTable';
import { WalletControlTabs } from 'widgets/WalletControlTabs';

import { DataTab } from '../DataTab/DataTab';

export function Office() {
  const tabs: TabsProps['items'] = [
    {
      key: 'data',
      label: <div>Данные офиса</div>,
      children: <DataTab />,
    },
    {
      key: 'commissions',
      label: <div>Комиссии</div>,
      children: <CommissionsTable type='offices-commissions' />,
    },
  ];

  return <WalletControlTabs tabs={tabs} />;
}
