import { Form } from 'antd';
import { IPayMethod, payMethodApi } from 'entities/PayMethod';
import { useEffect, useState } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export const usePayMethodsLogic = () => {
  const { showError, showSuccess } = useAppMessage();
  const [addPayMethodForm] = Form.useForm();

  // Add pay methods state
  const [isAddPayMethodModalOpen, setIsAddPayMethodModalOpen] = useState<boolean>(false);
  const newDescription = Form.useWatch('description', addPayMethodForm);
  const newSystemName = Form.useWatch('systemName', addPayMethodForm);
  const newIsEnabled = Form.useWatch('isEnabled', addPayMethodForm);
  const newIsHidden = Form.useWatch('isHidden', addPayMethodForm);
  const newName = Form.useWatch('name', addPayMethodForm);

  // Pay methods state
  const [totalPayMethodsCount, setTotalPayMethodsCount] = useState<number>(0);
  const [payMethods, setPayMethods] = useState<IPayMethod[]>([]);

  // Pay methods actions
  const [getPayMethodsRequest, getPayMethodsResult] = payMethodApi.useLazyGetPayMethodsQuery();
  const [addPayMethodRequest, addPayMethodResult] = payMethodApi.useAddPayMethodMutation();

  const addPayMethod = async () => {
    try {
      const result = await addPayMethodRequest({
        name: newName,
        isHidden: newIsHidden,
        systemName: newSystemName,
        isEnabled: newIsEnabled,
        description: newDescription,
      }).unwrap();

      setPayMethods(result.list);
      setTotalPayMethodsCount(result.total);
      addPayMethodForm.resetFields();
      showSuccess('Платежный метод добавлен');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const getPayMethods = async () => {
    try {
      const result = await getPayMethodsRequest({}).unwrap();
      setPayMethods(result.list);
      setTotalPayMethodsCount(result.total);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleIsAddPayMethodModalOpen = () => {
    setIsAddPayMethodModalOpen((prev) => !prev);
  };

  useEffect(() => {
    getPayMethods();
  }, []);

  return {
    flow: {
      addPayMethod,
      getPayMethods,
      handleIsAddPayMethodModalOpen,
    },
    state: {
      payMethods,
      addPayMethodForm,
      totalPayMethodsCount,
      isAddPayMethodModalOpen,
      isLoading: getPayMethodsResult.isLoading || addPayMethodResult.isLoading,
      isAddPayMethodSubmitBtnDisabled: !newName || !newSystemName || !newDescription,
    },
  };
};

export type TUsePayMethodsLogic = ReturnType<typeof usePayMethodsLogic>;
