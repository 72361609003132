import * as types from './countryApiTypes';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';

export const countryApi = createApi({
  reducerPath: 'countryApi',
  baseQuery,
  endpoints: (builder) => ({
    getCountries: builder.query<types.IGetCountriesResult, types.IGetCountriesParams>({
      query: (params) => {
        const updatedParams: Record<string, string | number | boolean | undefined> = {
          'filter[name]': params.name,
          'filter[isEnabled]': params.isEnabled,
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          fullList: params.fullList,
        };

        return {
          url: 'wallet/countries',
          params: updatedParams,
        };
      },
    }),
    addCountry: builder.mutation<types.IAddCountryResult, types.IAddCountryParams>({
      query: (body) => ({
        url: 'wallet/countries',
        method: 'POST',
        body,
      }),
    }),
    getCountriesCount: builder.query<types.IGetCountriesCountResult, types.IGetCountriesCountParams>({
      query: (params) => {
        const updatedParams: Record<string, string | number | boolean | undefined> = {
          'filter[name]': params.name,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: 'wallet/countries/count',
          params: updatedParams,
        };
      },
    }),
    getCountry: builder.query<types.IGetCountryResult, types.IGetCountryParams>({
      query: (params) => ({
        url: `wallet/countries/${params.countryCode}`,
      }),
    }),
    editCountry: builder.mutation<types.IEditCountryResult, types.IEditCountryParams>({
      query: ({ countryCode, ...body }) => ({
        url: `wallet/countries/${countryCode}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCountry: builder.mutation<types.IDeleteCountryResult, types.IDeleteCountryParams>({
      query: (params) => ({
        url: `wallet/countries/${params.countryCode}`,
        method: 'DELETE',
      }),
    }),
    getCountryPayMethods: builder.query<types.IGetCountryPayMethodsResult, types.IGetCountryPayMethodsParams>({
      query: (params) => ({
        url: `wallet/countries/${params.countryCode}/pay-methods`,
      }),
    }),
    addCountryPayMethod: builder.mutation<types.IAddCountryPayMethodResult, types.IAddCountryPayMethodParams>({
      query: ({ operations, ...params }) => ({
        url: `wallet/countries/${params.countryCode}/pay-methods/${params.payMethodId}`,
        method: 'POST',
        body: { operations },
      }),
    }),
    deleteCountryPayMethod: builder.mutation<types.IDeleteCountryPayMethodResult, types.IDeleteCountryPayMethodParams>({
      query: (params) => ({
        url: `wallet/countries/${params.countryCode}/pay-methods/${params.payMethodId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
