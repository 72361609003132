import { IComment } from 'entities/Comment';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICommentsSliceSchema } from '../types/commentsSliceSchema';

const initialState: ICommentsSliceSchema = {
  total: 0,
  comments: [],
  filterAuthorId: undefined,
  filterUpdatedAt: 'ASC',
  filterIsEnabled: true,
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    setComments(state, action: PayloadAction<IComment[]>) {
      state.comments = action.payload;
    },
    setFilterAuthorId(state, action: PayloadAction<string | undefined>) {
      state.filterAuthorId = action.payload;
    },
    setFilterUpdatedAt(state, action: PayloadAction<'ASC' | 'DESC' | undefined>) {
      state.filterUpdatedAt = action.payload;
    },
    setFilterIsEnabled(state, action: PayloadAction<boolean | undefined>) {
      state.filterIsEnabled = action.payload;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const commentsReducer = commentsSlice.reducer;
export const commentsActions = commentsSlice.actions;
