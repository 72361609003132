import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IBank, IBanksRequest, IBanksResponse, TAddBankRequest, TPUTBankRequest } from '../model/types';

export const banksApi = createApi({
  reducerPath: 'banksApi',
  baseQuery,
  endpoints: (builder) => ({
    getBanks: builder.query<IBanksResponse, IBanksRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'sorting[bankName]': params.nameFilter ?? 'ASC',
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[paySystem]': params.paySystem,
          'filter[bankName]': params.name,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/wallet/banks/`,
          params: updatedParams,
        };
      },
    }),
    getBanksCount: builder.query<{ total: number }, IBanksRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'sorting[bankName]': params.nameFilter ?? 'ASC',
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[paySystem]': params.paySystem,
          'filter[bankName]': params.name,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/wallet/banks/count`,
          params: updatedParams,
        };
      },
    }),
    getBank: builder.query<{ data: IBank }, { bankId: number }>({
      query: ({ bankId }) => {
        return {
          url: `/wallet/banks/${bankId}`,
        };
      },
    }),
    putBank: builder.mutation<{ data: IBank }, { bankId: number; body: TPUTBankRequest | undefined }>({
      query: ({ bankId, body }) => {
        return {
          url: `/wallet/banks/${bankId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    addBank: builder.mutation<IBanksResponse, TAddBankRequest>({
      query: (body) => {
        return {
          url: `/wallet/banks`,
          method: 'POST',
          body,
        };
      },
    }),
    disableBank: builder.mutation<{ data: IBank }, { bankId: number }>({
      query: ({ bankId }) => {
        return {
          url: `/wallet/banks/${bankId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});
