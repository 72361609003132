import { Card, Col, Divider, Flex, Form, Input, Row, Segmented, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { emptyStringToUndefined, paySystemMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

import { bankStatusOptions } from '../../model/bankStatusOptions';
import { useFiltersLogic } from '../../model/useFiltersLogic';
import { AddBank } from '../AddBank/AddBank';

import styles from './filters.module.scss';

export function Filters() {
  const logic = useFiltersLogic();

  return (
    <Card className={styles.filters}>
      <Flex align='center' justify='space-between'>
        <Flex vertical>
          <Typography.Title level={3} style={{ marginBottom: 3 }} children='Банки' />
          <Typography.Text type='secondary' children='Номенклатура' />
        </Flex>

        <AddBank />
      </Flex>

      <Divider />

      <Typography.Title level={3} style={{ marginBottom: 21 }} children='Фильтры' />

      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            {/* <FormItem label='ID города' className={styles.form_item}>
              <Input value={logic.cityIdFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setCityIdFilter)} />
            </FormItem> */}
            <FormItem label='Название' className={styles.form_item}>
              <Input value={logic.nameFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setNameFilter)} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Платежная система' className={styles.form_item}>
              <CustomSelect
                id={'pay-system-filter'}
                className={styles.net_select}
                value={logic.paySystemFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setPaySystemFilter)}
                emptyOption={{ value: '', label: 'Все' }}
                options={logic.paySystems?.list?.map((item) => ({
                  value: item,
                  label: paySystemMapping[item as keyof typeof paySystemMapping] ?? item,
                }))}
                error={false}
              />
            </FormItem>
          </Col>
          <Col>
            <FormItem label='Статус'>
              <Segmented value={logic.isEnabledFilter} options={bankStatusOptions} onChange={logic.showEnabledBanks} />
            </FormItem>
          </Col>
        </Row>
      </Form>

      <div className={styles.filters_btns}>
        <Button onClick={logic.applyFilters}>Применить</Button>
        <Button onClick={logic.resetFilters}>Отменить</Button>
      </div>
    </Card>
  );
}
