import { Dayjs } from 'dayjs';
import { useGetFinopsQuery, useGetPaySystemsQuery } from 'entities/Finops';
import { useGetTransactionStatusesQuery } from 'entities/Transactions';
import { useContext, useEffect, useState } from 'react';

import { FinOperationsContext } from '../../ui/FinOperationsTable/FinOperationsTable';

export function useFiltersLogic() {
  const context = useContext(FinOperationsContext);

  const [transactionIdFilter, setTransactionIdFilter] = useState<string | undefined>();
  const [paymentCodeFilter, setPaymentCodeFilter] = useState<string | undefined>();
  const [userIdFilter, setUserIdFilter] = useState<string | undefined>();
  const [walletIdFilter, setWalletIdFilter] = useState<string | undefined>();
  const [currencyCodeFilter, setCurrencyCodeFilter] = useState<string | undefined>();
  const [operationTypeFilter, setOperationTypeFilter] = useState<string | undefined>();
  const [transactionTypeFilter, setTransactionTypeFilter] = useState<string | undefined>();
  const [statusFilter, setStatusFilter] = useState<string | undefined>();
  const [createdFromFilter, setCreatedFromFilter] = useState<Dayjs | null | undefined>();
  const [createdToFilter, setCreatedToFilter] = useState<Dayjs | null | undefined>();
  const [payMethodFilter, setPayMethodFilter] = useState<string | undefined>();
  const [paySystemFilter, setPaySystemFilter] = useState<string | undefined>();
  const [isManualFilter, setIsManualFilter] = useState();

  const { data: paySystems } = useGetPaySystemsQuery({});
  const { data: transactionStatuses } = useGetTransactionStatusesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const { data: finops } = useGetFinopsQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const resetFilters = () => {
    setTransactionIdFilter(undefined);
    setUserIdFilter(undefined);
    setWalletIdFilter(undefined);
    setCurrencyCodeFilter(undefined);
    setOperationTypeFilter(undefined);
    setTransactionTypeFilter(undefined);
    setStatusFilter(undefined);
    setCreatedFromFilter(undefined);
    setCreatedToFilter(undefined);
    setPayMethodFilter(undefined);
    setPaySystemFilter(undefined);
    setPaymentCodeFilter(undefined);

    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getTransactions({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context.currentPage,
      perPage: context.perPage,
      transactionId: transactionIdFilter,
      paymentCode: paymentCodeFilter,
      userId: userIdFilter,
      walletId: walletIdFilter,
      currencyCode: currencyCodeFilter,
      operationType: operationTypeFilter,
      transactionType: transactionTypeFilter,
      status: statusFilter,
      createdFrom: createdFromFilter?.toISOString(),
      createdTo: createdToFilter?.toISOString(),
      isManual: isManualFilter,
      payMethod: window.location.pathname.includes('cash') ? 'CASH' : payMethodFilter,
      paySystem: paySystemFilter,
    });
    context?.getTransactionsCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      transactionId: transactionIdFilter,
      paymentCode: paymentCodeFilter,
      userId: userIdFilter,
      walletId: walletIdFilter,
      currencyCode: currencyCodeFilter,
      operationType: operationTypeFilter,
      transactionType: transactionTypeFilter,
      status: statusFilter,
      createdFrom: createdFromFilter?.toISOString(),
      createdTo: createdToFilter?.toISOString(),
      isManual: isManualFilter,
      payMethod: window.location.pathname.includes('cash') ? 'CASH' : payMethodFilter,
      paySystem: paySystemFilter,
    });
  };

  function getDataWithoutFilters() {
    context?.getTransactions({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context.currentPage,
      perPage: context.perPage,
      payMethod: window.location.pathname.includes('cash') ? 'CASH' : undefined, // для первой загрузки страницы CASH
    });
    context?.getTransactionsCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      payMethod: window.location.pathname.includes('cash') ? 'CASH' : undefined, // для первой загрузки страницы CASH
    });
  }

  useEffect(() => {
    getDataWithoutFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    transactionIdFilter,
    paymentCodeFilter,
    setTransactionIdFilter,
    userIdFilter,
    setUserIdFilter,
    walletIdFilter,
    setWalletIdFilter,
    currencyCodeFilter,
    setCurrencyCodeFilter,
    operationTypeFilter,
    setOperationTypeFilter,
    finops,
    transactionTypeFilter,
    setTransactionTypeFilter,
    statusFilter,
    setStatusFilter,
    transactionStatuses,
    paySystemFilter,
    setPaySystemFilter,
    payMethodFilter,
    setPayMethodFilter,
    createdFromFilter,
    setCreatedFromFilter,
    createdToFilter,
    setCreatedToFilter,
    applyFilters,
    resetFilters,
    setPaymentCodeFilter,
    paySystems,
  };
}
