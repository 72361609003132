import { Col, Form, Row, Table, TableColumnsType } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useGetPaySystemsQuery } from 'entities/Finops';
import { banksApi, IBank } from 'features/BanksTable';
import { useState } from 'react';
import { emptyStringToUndefined, paySystemMapping } from 'shared/constants';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { useAppMessage } from 'utils/messageContext';

import { UserAddOutlined } from '@ant-design/icons';

import styles from './addBank.module.scss';

export function AddBank() {
  const [addNewCourier, setAddNewCourier] = useState(false);
  const [name, setName] = useState('');
  const [paySystem, setPaySystem] = useState<IBank['paySystem']>();
  const [countryCode, setCountryCode] = useState('');

  const [addBank, addBankResponse] = banksApi.useAddBankMutation();
  const { showError, showSuccess } = useAppMessage();

  const { data: paySystems } = useGetPaySystemsQuery({});

  const onFinish = async () => {
    try {
      //TODO add a validation
      await addBank({
        bankName: name,
        paySystem: paySystem && [paySystem as keyof IBank['paySystem']],
        countryCode,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  return (
    <>
      <Button onClick={() => setAddNewCourier(true)} className={styles.add_courier_btn}>
        Добавить банк +
      </Button>

      <CustomModal
        width={660}
        className={styles.modal}
        isOpen={addNewCourier}
        onClose={() => setAddNewCourier(false)}
        centered
        getContainer={document.body} // fixed bug in safari. when u close the modal page is scrolled down
      >
        <ModalTitle>
          <span>Добавить банк</span>
        </ModalTitle>
        <br />
        <Form layout='vertical' className={styles.form} onFinish={onFinish}>
          <FormItem label='Название' className={styles.form_item}>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormItem>
          <FormItem label='Платежная система' className={styles.form_item}>
            <CustomSelect
              id={'pay-system-filter'}
              className={styles.net_select}
              value={paySystem ?? 'Все'}
              onChange={(value) => emptyStringToUndefined(value, setPaySystem as (value: string | undefined) => void)}
              emptyOption={{ value: '', label: 'Все' }}
              options={paySystems?.list?.map((item) => ({
                value: item,
                label: paySystemMapping[item as keyof typeof paySystemMapping] ?? item,
              }))}
              error={false}
            />
          </FormItem>
          {/* <FormItem label='ID города' className={styles.form_item}>
            <Input value={cityId ?? ''} onChange={(e) => setCityId(e.target.value)} />
          </FormItem> */}

          <FormItem label='Код страны' className={styles.form_item}>
            <Input value={countryCode} onChange={(e) => setCountryCode(e.target.value)} />
          </FormItem>

          <Button className={styles.confirm_btn}>Добавить</Button>
        </Form>
      </CustomModal>
    </>
  );
}
