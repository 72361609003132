import { Card, Col, Form, Row, Skeleton } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ChangeFinOperationStatusWindow } from 'features/FinOperationsTable';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';
import { cn } from 'utils/classnames';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { useCourierLogic } from '../../model/useCourierLogic';
import { CourierSchedule } from '../CourierSchedule/CourierSchedule';

import styles from './dataTab.module.scss';

export function DataTab() {
  const logic = useCourierLogic();

  const navigate = useNavigate();

  return logic.isLoading || logic.isFetching ? (
    <Skeleton active />
  ) : (
    <div className={styles.wrapper}>
      <Card title='Информация'>
        <Form layout='vertical' className={styles.form}>
          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='ID Курьера (UID)' className={styles.form_item}>
                <Input value={logic.data?.courierId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Имя' className={styles.form_item}>
                <InputEditable
                  name='name'
                  placeholder='Имя'
                  value={logic.newName ?? logic.data?.name ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewName(e.target.value)}
                  onCancel={() => logic.setNewName(logic.data?.name ?? '')}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Адрес' className={styles.form_item}>
                <InputEditable
                  name='address'
                  placeholder='Адрес'
                  value={logic.newAddress ?? logic.data?.address ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewAddress(e.target.value)}
                  onCancel={() => logic.setNewAddress(logic.data?.address ?? '')}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='ID города' className={styles.form_item}>
                <InputEditable
                  name='cityId'
                  placeholder='ID города'
                  value={logic.newCityId ?? logic.data?.cityId ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCityId(e.target.value)}
                  onCancel={() => logic.setNewCityId(logic.data?.cityId ?? '')}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Город' className={styles.form_item}>
                <InputEditable
                  name='cityName'
                  placeholder='Город'
                  value={logic.newCityName ?? logic.data?.cityName ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCityName(e.target.value)}
                  onCancel={() => logic.setNewCityName(logic.data?.cityName ?? '')}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Код города' className={styles.form_item}>
                <InputEditable
                  name='countryCode'
                  placeholder='Код города'
                  value={logic.newCountryCode ?? logic.data?.countryCode ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCountryCode(e.target.value)}
                  onCancel={() => logic.setNewCountryCode(logic.data?.countryCode ?? '')}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Страна' className={styles.form_item}>
                <InputEditable
                  name='countryName'
                  placeholder='Страна'
                  value={logic.newCountryName ?? logic.data?.countryName ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCountryName(e.target.value)}
                  onCancel={() => logic.setNewCountryName(logic.data?.countryName ?? '')}
                />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem label='Статус' className={cn(styles.form_item, styles.form_item_with_btn)}>
                <Input value={logic.data?.state ?? ''} readOnly disabled />
                {logic.data?.state && (
                  <Button className={styles.form_item_btn} onClick={logic.openChangeStatusWindow}>
                    Изменить
                  </Button>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Дата создания' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.createdAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Дата изменения' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.updatedAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}></Col>
            <Col span={6}>
              <FormItem label=' ' className={styles.form_item}>
                <Button onClick={logic.disableCourierHandler}>Отключить курьера</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <ChangeFinOperationStatusWindow
          isOpened={Boolean(logic.editTransactionId)}
          onClose={() => logic.setEditTransactionId(null)}
          onSubmit={logic.changeCourierState}
          editTransactionId={logic.editTransactionId ?? 'no id'}
          selectedStatus={logic.courierState ?? 'no status'}
          onChange={(value) => logic.setCourierState(value)}
        />
      </Card>

      <Card title='Расписание'>
        <CourierSchedule />
      </Card>

      <div className={styles.control_btns}>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
    </div>
  );
}
