import { Col, Flex, Row, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { ITransactionLogInfo } from 'entities/Transactions';
import { useState } from 'react';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import * as AntdIcons from '@ant-design/icons';

import styles from './logItem.module.scss';

export function LogItem({ logData }: { logData: ITransactionLogInfo }) {
  const [isSnapshotOpened, setIsSnapshotOpened] = useState(false);

  return (
    <>
      <Flex vertical gap={6} className={styles.log}>
        <Flex gap={9}>
          <Typography.Text className={styles.log_author}>{logData.userId}</Typography.Text>
          <Typography.Text>•</Typography.Text>
          <Typography.Text type='secondary'>Добавлен {dayjs(logData.createdAt).format('DD.MM.YYYY')}</Typography.Text>
        </Flex>

        <Typography.Text>{logData.comment}</Typography.Text>

        <Flex gap={9} className={styles.log_actions}>
          <Flex align='center' gap={6} className={styles.log_actions_action} onClick={() => setIsSnapshotOpened(true)}>
            <AntdIcons.FileTextOutlined style={{ color: '#007AFF' }} />
            <Typography.Text style={{ color: '#007AFF' }}>Снапшот</Typography.Text>
          </Flex>
        </Flex>
      </Flex>

      <CustomModal
        width={760}
        className={styles.modal}
        isOpen={isSnapshotOpened}
        onClose={() => setIsSnapshotOpened(false)}
        centered
        getContainer={document.body} // fixed bug in safari. when u close the modal page is scrolled down
      >
        <ModalTitle>
          <AntdIcons.FileTextOutlined />
          <span>Снапшот id: {logData.userId}</span>
        </ModalTitle>
        <br />

        <Row gutter={16}>
          <Col span={8}>
            <Title level={4}>Параметр</Title>
          </Col>
          <Col span={8}>
            <Title level={4}>Snapshot до</Title>
          </Col>
          <Col span={8}>
            <Title level={4}>Snapshot после</Title>
          </Col>
        </Row>
        {Object.entries(logData.snapshotBefore).map(([key, value]) => {
          const isDate = key === 'createdAt' || key === 'updatedAt';
          const valueAfter = logData.snapshotAfter[key as keyof ITransactionLogInfo['snapshotBefore']];

          return (
            <Row gutter={16} className={styles.row}>
              <Col span={8}>{key.toUpperCase()}</Col>
              <Col span={8}>{isDate ? transformDateToUserFriendly(value as string) : String(value)}</Col>
              <Col span={8}>{isDate ? transformDateToUserFriendly(valueAfter as string) : String(valueAfter)}</Col>
            </Row>
          );
        })}
      </CustomModal>
    </>
  );
}
