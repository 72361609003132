import { DefaultOptionType } from 'antd/es/select';
import { useAppDispatch } from 'app/GlobalRedux/store';
import { IAdminUser, IGetAdminUsersResponse, useLazyGetUsersQuery } from 'entities/AdminUser';
import { useCreateCommentMutation, useLazyGetCommentsQuery, IComment } from 'entities/Comment';
import { IGetCommentsResponse } from 'entities/Comment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptyStringValueToUndefined } from 'shared/constants';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import * as selectors from '../selectors/commentsSelectors';
import { commentsActions } from '../slices/commentsSlice';

export const useCommentsLogic = () => {
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useAppMessage();
  const query = useParams();

  const totalCommentsCount: number = useSelector(selectors.getCommentsTotal);
  const comments: IComment[] = useSelector(selectors.getComments);

  const [isNewComment, setIsNewComment] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>('');
  const [adminUsers, setAdminUsers] = useState<IAdminUser[]>([]);

  const filterIsEnabled: boolean | undefined = useSelector(selectors.getFilterIsEnabled);
  const filterUpdatedAt: 'ASC' | 'DESC' | undefined = useSelector(selectors.getFilterUpdatedAt);
  const filterAuthorId: string | undefined = useSelector(selectors.getFilterAuthorId);

  const [getAdminUsersRequest] = useLazyGetUsersQuery();
  const [createCommentRequest] = useCreateCommentMutation();
  const [getCommentsRequest] = useLazyGetCommentsQuery();

  const handleGetComments = async () => {
    try {
      dispatch(commentsActions.setComments([]));
      dispatch(commentsActions.setTotal(0));

      const userId: string | undefined = query.id;
      if (!userId) return;

      const result: IGetCommentsResponse = await getCommentsRequest({
        userId,
        authorId: emptyStringValueToUndefined(filterAuthorId),
        updatedAt: filterUpdatedAt,
        isEnabled: filterIsEnabled,
      }).unwrap();

      dispatch(commentsActions.setComments(result.list));
      dispatch(commentsActions.setTotal(result.total));
    } catch (e) {
      // showError(e as IResponseError);
      dispatch(commentsActions.setComments([]));
      dispatch(commentsActions.setTotal(0));
    }
  };

  const handleCreateComment = async () => {
    try {
      const userId: string | undefined = query.id;
      if (!userId || !newComment) return;
      await createCommentRequest({ userId, comment: newComment }).unwrap();
      handleGetComments();
      showSuccess('Комментарий добавлен');
      setNewComment('');
      setIsNewComment(false);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleGetAdminUsers = async () => {
    try {
      const result: IGetAdminUsersResponse = await getAdminUsersRequest({}).unwrap();
      setAdminUsers(result.list);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleNewCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  const handleIsNewCommentChange = () => {
    setIsNewComment((prev) => !prev);

    if (isNewComment) {
      setNewComment('');
    }
  };

  const handleFilterAuthorIdChange = (authorId: string) => {
    dispatch(commentsActions.setFilterAuthorId(authorId));
  };

  const handleFilterUpdatedAtChange = (filter: 'ASC' | 'DESC') => {
    dispatch(commentsActions.setFilterUpdatedAt(filter));
  };

  const handleFilterIsEnabledChange = (status: boolean) => {
    dispatch(commentsActions.setFilterIsEnabled(status));
  };

  const getFilterAuthorIdOptions = (): DefaultOptionType[] => {
    if (!adminUsers?.length) return [];
    return adminUsers.map((adminUser): DefaultOptionType => ({ label: adminUser.login, value: adminUser.userId }));
  };

  const getFilterUpdatedAtOptions = (): DefaultOptionType[] => {
    return [
      { label: 'Сортировать по времени изменения (сначала недавние)', value: 'DESC' },
      { label: 'Сортировать по времени изменения (сначала старые)', value: 'ASC' },
    ];
  };

  useEffect(() => {
    handleGetComments();
  }, [query, filterUpdatedAt, filterIsEnabled, filterAuthorId]);

  useEffect(() => {
    handleGetAdminUsers();

    return () => {
      dispatch(commentsActions.resetState());
    };
  }, [dispatch]);

  return {
    flow: {
      handleCreateComment,
      handleNewCommentChange,
      handleIsNewCommentChange,
      getFilterAuthorIdOptions,
      getFilterUpdatedAtOptions,
      handleFilterIsEnabledChange,
      handleFilterAuthorIdChange,
      handleFilterUpdatedAtChange,
    },
    state: {
      comments,
      adminUsers,
      isNewComment,
      filterAuthorId,
      filterUpdatedAt,
      filterIsEnabled,
      totalCommentsCount,
    },
  };
};

export type TUseCommentsLogic = ReturnType<typeof useCommentsLogic>;
