import { Form } from 'antd';
import { countryApi, ICountry, IEditCountryParams, IGetCountryPayMethodsResult } from 'entities/Country';
import { IOperation } from 'entities/Finops';
import { IPayMethod } from 'entities/PayMethod';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export const useCountryLogic = () => {
  const { showError, showSuccess } = useAppMessage();
  const params = useParams();
  const [form] = Form.useForm();

  // Edit country state
  const [isAddCountryPayMethodModalOpen, setIsAddCountryPayMethodModalOpen] = useState<boolean>(false);
  const [countryPayMethodIdToAdd, setCountryPayMethodIdToAdd] = useState<number>();
  const [finOpsToAdd, setFinOpsToAdd] = useState<IOperation[]>([]);
  const [countryName, setCountryName] = useState<string | undefined>('');

  // Country state
  const [totalCountryPayMethods, setTotalCountryPayMethods] = useState<number>(0);
  const [countryPayMethods, setCountryPayMethods] = useState<IPayMethod[]>([]);
  const [country, setCountry] = useState<ICountry>();

  // Country actions
  const [deleteCountryPayMethodRequest, deleteCountryPayMethodResult] = countryApi.useDeleteCountryPayMethodMutation();
  const [getCountryPayMethodsRequest, getCountryPayMethodsResult] = countryApi.useLazyGetCountryPayMethodsQuery();
  const [addCountryPayMethodRequest, addCountryPayMethodResult] = countryApi.useAddCountryPayMethodMutation();
  const [editCountryRequest, editCountryResult] = countryApi.useEditCountryMutation();
  const [getCountryRequest, getCountryResult] = countryApi.useLazyGetCountryQuery();

  const getCountry = async (countryCode: string) => {
    try {
      const countryResult = await getCountryRequest({ countryCode }).unwrap();
      const countryPayMethodsResult: IGetCountryPayMethodsResult = await getCountryPayMethodsRequest({ countryCode }).unwrap();
      setCountry(countryResult?.data);
      setCountryPayMethods(countryPayMethodsResult?.list ?? []);
      setTotalCountryPayMethods(countryPayMethodsResult?.total ?? 0);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const editCountry = async (params: Omit<IEditCountryParams, 'countryCode'>) => {
    try {
      const result = await editCountryRequest({ countryCode: country?.countryCode as string, ...params }).unwrap();
      setCountry(result.data);
      showSuccess('Изменения успешно сохранены');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const addCountryPayMethod = async () => {
    try {
      if (!country || !countryPayMethodIdToAdd) return;

      const result = await addCountryPayMethodRequest({
        operations: finOpsToAdd,
        countryCode: country?.countryCode,
        payMethodId: countryPayMethodIdToAdd,
      }).unwrap();

      setCountryPayMethods(result.list);
      setTotalCountryPayMethods(result.total);
      setCountryPayMethodIdToAdd(undefined);
      setIsAddCountryPayMethodModalOpen(false);
      showSuccess('Платежный метод успешно добавлен');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const deleteCountryPayMethod = async (payMethodId: number) => {
    try {
      if (!country) return;
      const result = await deleteCountryPayMethodRequest({ countryCode: country?.countryCode, payMethodId }).unwrap();
      setCountryPayMethods(result.list);
      setTotalCountryPayMethods(result.total);
      showSuccess('Платежный метод успешно удален');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleCountryNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCountryName(e.target.value);
  };

  const handleFinOpsToAddChange = (value: IOperation[]) => {
    setFinOpsToAdd(value);
  };

  const handleCountryIsEnabledChange = () => {
    editCountry({ isEnabled: !country?.isEnabled });
  };

  const handleCountryNameChangeSubmit = () => {
    setCountryName('');
    editCountry({ name: countryName });
  };

  const handleOpenAddCountryPayMethodModal = () => {
    setIsAddCountryPayMethodModalOpen((prev) => !prev);
  };

  const handleCountryPayMethodIdToAddChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCountryPayMethodIdToAdd(e.target.value ? Number(e.target.value) : undefined);
  };

  useEffect(() => {
    params?.countryCode && getCountry(params.countryCode);
  }, [params]);

  return {
    flow: {
      addCountryPayMethod,
      deleteCountryPayMethod,
      handleCountryNameChange,
      handleFinOpsToAddChange,
      handleCountryIsEnabledChange,
      handleCountryNameChangeSubmit,
      handleOpenAddCountryPayMethodModal,
      handleCountryPayMethodIdToAddChange,
    },
    state: {
      form,
      country,
      countryPayMethods,
      totalCountryPayMethods,
      isAddCountryPayMethodModalOpen,
      countryName: countryName,
      isAddCountryPayMethodBtnDisabled: !countryPayMethodIdToAdd || !finOpsToAdd.length,
      isLoading:
        getCountryResult.isLoading ||
        editCountryResult.isLoading ||
        addCountryPayMethodResult.isLoading ||
        getCountryPayMethodsResult.isLoading ||
        deleteCountryPayMethodResult.isLoading,
    },
  };
};

export type TUseCountryLogic = ReturnType<typeof useCountryLogic>;
