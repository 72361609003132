import { TPanelType } from 'shared/types/Types/TPanelType';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';

export interface IResponse {
  list: (string | undefined)[];
}

export interface IRequest {
  panelType: TPanelType;
}

export const finopsApi = createApi({
  reducerPath: 'finopsApi',
  baseQuery,
  endpoints: (builder) => ({
    getFinops: builder.query<IResponse, IRequest>({
      query: (params) => {
        return {
          url: `/${params.panelType}/finops`,
          params,
        };
      },
    }),
    getPaySystems: builder.query<IResponse, unknown>({
      query: () => {
        return {
          url: `/wallet/pay-systems`,
        };
      },
    }),
  }),
});

export const { useGetFinopsQuery, useGetPaySystemsQuery } = finopsApi;
