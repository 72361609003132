import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { createContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { ICourier } from '../../model/types';
import { TFinOperationsTableLogic, useCouriersTableLogic } from '../../model/useCouriersTableLogic';
import { ChangeCourierStateWindow } from '../ChangeCourierStateWindow/ChangeCourierStateWindow';
import { Filters } from '../Filters/Filters';

export const CouriersTableContext = createContext<TFinOperationsTableLogic | null>(null);

export function CouriersTable() {
  const logic = useCouriersTableLogic();

  const columns: ColumnsType<ICourier> = [
    {
      title: 'UID',
      dataIndex: 'courierId',
      key: 'courierId',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => transformDateToUserFriendly(date),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortOrder: logic.sortedInfo.columnKey === 'createdAt' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: logic.sortedInfo.columnKey === 'name' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Город',
      dataIndex: 'countryName',
      key: 'countryName',
      // sorter: (a, b) => a.transactionType.localeCompare(b.transactionType),
      // sortOrder: logic.sortedInfo.columnKey === 'transactionType' ? logic.sortedInfo.order : null,
      // render: (value) => transactionTypeMapping[value],
    },
    // {
    //   title: 'Расписание',
    //   dataIndex: 'schedule',
    //   key: 'schedule',
    //   render: (value) => `${value.monday.begin}`,
    // },
    {
      title: 'Статус',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortOrder: logic.sortedInfo.columnKey === 'state' ? logic.sortedInfo.order : null,
      // render: (value: keyof typeof transactionStatusMapping) => transactionStatusMapping[value],
    },
    {
      title: 'Подробнее',
      dataIndex: 'more',
      key: 'more',
      render: (_, record) => {
        return (
          <NavLink to={`${window.location.pathname}/${record.courierId}`}>
            <Button>Открыть</Button>
          </NavLink>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'btn',
      render: (_, record) => <Button onClick={() => logic.setEditCourierId(record.courierId)}>Изменить состояние</Button>,
    },
  ];

  return (
    <CouriersTableContext.Provider value={logic}>
      <Filters />

      <Table
        rowKey={(row) => row.courierId}
        columns={columns}
        dataSource={logic.data?.list}
        loading={logic.isLoading || logic.isFetching}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          total: logic.transactionsCount,
          showTotal: (value) => `Всего курьеров: ${value}`,
          locale: { items_per_page: 'на стр.' },
        }}
      />

      <ChangeCourierStateWindow
        isOpened={Boolean(logic.editCourierId)}
        onClose={() => logic.setEditCourierId(null)}
        onSubmit={logic.changeCourierState}
        editCourierId={logic.editCourierId ?? 'no id'}
        selectedState={logic.courierState ?? 'no status'}
        onChange={(value: string) => logic.setCourierState(value)}
      />
    </CouriersTableContext.Provider>
  );
}
