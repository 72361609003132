import { ITransaction } from 'entities/Transactions';
import { banksApi, IBank } from 'features/BanksTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export function useBankLogic() {
  const { id } = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [bankData, setBankData] = useState<IBank | undefined>();

  const [newName, setNewName] = useState<string | undefined>();
  const [newAddress, setNewAddress] = useState<string | undefined>();
  const [newCityId, setNewCityId] = useState<string | undefined>();
  const [newCityName, setNewCityName] = useState<string | undefined>();
  const [newCountryCode, setNewCountryCode] = useState<string | undefined>();
  const [newCountryName, setNewCountryName] = useState<string | undefined>();
  const [editTransactionId, setEditTransactionId] = useState<ITransaction['transactionId'] | null>(null);
  const [disableBank] = banksApi.useDisableBankMutation();
  const [putBank] = banksApi.usePutBankMutation();
  const { data, isLoading, isFetching } = banksApi.useGetBankQuery({ bankId: id ? +id : -0 }, { refetchOnMountOrArgChange: true });

  function openChangeStatusWindow() {
    setEditTransactionId(id ?? '');
  }

  async function disableBankHandler() {
    try {
      await disableBank({ bankId: id ? +id : -0 }).unwrap();
      showSuccess('Успех');

      if (!data) return;
      setBankData({ ...data.data, isEnabled: false });
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  async function enableBankHandler() {
    try {
      await putBank({ bankId: id ? +id : -0, body: { isEnabled: true } }).unwrap();
      showSuccess('Успех');

      if (!data) return;
      setBankData({ ...data.data, isEnabled: true });
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  const confirmChanges = async () => {
    try {
      await putBank({
        body: {
          bankName: newName,
          countryCode: newCountryCode,
          countryName: newCountryName,
        },
        bankId: id ? +id : 0,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  // useEffect(() => {
  //   if (data?.data.state) setCourierState(data?.data.state);
  // }, [data?.data.state]);

  useEffect(() => {
    if (data?.data) setBankData(data.data);
  }, [data?.data]);

  return {
    data: bankData,
    isLoading,
    isFetching,
    openChangeStatusWindow,
    editTransactionId,
    setEditTransactionId,
    disableBankHandler,
    newName,
    setNewName,
    confirmChanges,
    newAddress,
    setNewAddress,
    newCityId,
    setNewCityId,
    newCityName,
    setNewCityName,
    newCountryCode,
    setNewCountryCode,
    newCountryName,
    setNewCountryName,
    enableBankHandler,
  };
}
