import { Table } from 'antd';
import React from 'react';

import { countriesTableColumns } from '../../model/consts/countriesTableColumns';
import { useCountriesLogic } from '../../model/hooks/useCountriesLogic';
import { AddCountryModal } from '../AddCountryModal/AddCountryModal';
import { CountriesFilters } from '../CountriesFilters/CountriesFilters';

export const Countries = () => {
  const logic = useCountriesLogic();

  return (
    <React.Fragment>
      <CountriesFilters logic={logic} />

      <Table
        scroll={{ x: 'scroll' }}
        rowKey={(row) => row.name}
        columns={countriesTableColumns}
        loading={logic.state.isLoading}
        onChange={logic.flow.handleTableChange}
        dataSource={logic.state.countries}
        pagination={{
          total: logic.state.total,
          locale: { items_per_page: 'на стр.' },
          current: logic.state.pageFilter,
          pageSize: logic.state.perPageFilter,
          position: ['topRight', 'bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 50,
          showSizeChanger: true,

          showTotal: (total: number) => {
            return `Всего стран: ${total}`;
          },

          onShowSizeChange: (currentPage: number, amount: number) => {
            logic.flow.setPageFilter(currentPage);
            logic.flow.setPerPageFilter(amount);
          },
        }}
      />

      <AddCountryModal logic={logic} />
    </React.Fragment>
  );
};
