import { Card, Col, Form, Row, Skeleton } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';
import { cn } from 'utils/classnames';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { useBankLogic } from '../../model/useBankLogic';

import styles from './dataTab.module.scss';

export function DataTab() {
  const logic = useBankLogic();

  const navigate = useNavigate();

  return logic.isLoading || logic.isFetching ? (
    <Skeleton active />
  ) : (
    <div className={styles.wrapper}>
      <Card title='Информация'>
        <Form layout='vertical' className={styles.form}>
          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='ID Банка (UID)' className={styles.form_item}>
                <Input value={logic.data?.bankId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Название' className={styles.form_item}>
                <InputEditable
                  name='name'
                  placeholder='Название'
                  value={logic.newName ?? logic.data?.bankName ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewName(e.target.value)}
                  onCancel={() => logic.setNewName(logic.data?.bankName ?? '')}
                />
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem label='Код города' className={styles.form_item}>
                <InputEditable
                  name='countryCode'
                  placeholder='Код города'
                  value={logic.newCountryCode ?? logic.data?.countryCode ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCountryCode(e.target.value)}
                  onCancel={() => logic.setNewCountryCode(logic.data?.countryCode ?? '')}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Страна' className={styles.form_item}>
                <InputEditable
                  name='countryName'
                  placeholder='Страна'
                  value={logic.newCountryName ?? logic.data?.countryName ?? ''}
                  onConfirm={logic.confirmChanges}
                  onChange={(e) => logic.setNewCountryName(e.target.value)}
                  onCancel={() => logic.setNewCountryName(logic.data?.countryName ?? '')}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Платежная система' className={styles.form_item}>
                <Input value={logic.data?.paySystem ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Дата создания' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.createdAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Дата изменения' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.updatedAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Активен' className={styles.form_item}>
                <Input value={logic.data?.isEnabled ? 'Да' : 'Нет'} readOnly disabled />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <FormItem label=' ' className={styles.form_item}>
                <Button onClick={logic.data?.isEnabled ? logic.disableBankHandler : logic.enableBankHandler}>
                  {logic.data?.isEnabled ? 'Отключить' : 'Включить'} банк
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>

      <div className={styles.control_btns}>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
    </div>
  );
}
