import * as types from './payMethodApiTypes';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '../../../shared/api';

export const payMethodApi = createApi({
  reducerPath: 'payMethodApi',
  baseQuery,
  endpoints: (builder) => ({
    getPayMethods: builder.query<types.IGetPayMethodsResult, types.IGetPayMethodsParams>({
      query: (params) => {
        const updatedParams: Record<string, string | boolean | number | undefined> = {
          'filter[isEnabled]': params.isEnabled,
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          fullList: params.fullList,
        };

        return {
          url: 'wallet/pay-methods',
          params: updatedParams,
        };
      },
    }),
    addPayMethod: builder.mutation<types.IAddPayMethodResult, types.IAddPayMethodParams>({
      query: (body) => ({
        url: 'wallet/pay-methods',
        method: 'POST',
        body,
      }),
    }),
    getPayMethodsCount: builder.query<types.IGetPayMethodsResult, types.IGetPayMethodsParams>({
      query: (params) => {
        const updatedParams: Record<string, string | boolean | number | undefined> = {
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: 'wallet/pay-methods/count',
          params: updatedParams,
        };
      },
    }),
    getPayMethod: builder.query<types.IGetPayMethodResult, types.IGetPayMethodParams>({
      query: (params) => ({
        url: `wallet/pay-methods/${params.payMethodId}`,
      }),
    }),
    editPayMethod: builder.mutation<types.IEditPayMethodResult, types.IEditPayMethodParams>({
      query: ({ payMethodId, ...body }) => ({
        url: `wallet/pay-methods/${payMethodId}`,
        method: 'PUT',
        body,
      }),
    }),
    deletePayMethod: builder.mutation<types.IDeletePayMethodResult, types.IDeletePayMethodParams>({
      query: (params) => ({
        url: `wallet/pay-methods/${params.payMethodId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
