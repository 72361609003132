import { FormEvent, useEffect, useState } from 'react';
import { transactionStatusMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';

import styles from './changeFinOperationStatusWindow.module.scss';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChange: (value: string) => void;
  editTransactionId: string;
  selectedStatus: string;
}

const transactionStatuses = ['decline', 'confirm'];

export const optionsMapper = {
  cancel: ['decline'],
  confirm: ['decline'],
  success: ['confirm'],
  decline: ['confirm'],
  create: transactionStatuses,
  payed: transactionStatuses,
};

export function ChangeFinOperationStatusWindow({ isOpened, onClose, onSubmit, onChange, editTransactionId, selectedStatus }: Props) {
  const [changedValue, setChangedValue] = useState<any>();

  useEffect(() => {
    if (!isOpened) setChangedValue(undefined);
  }, [isOpened]);

  return (
    <CustomModal
      width={560}
      className={styles.modal}
      isOpen={isOpened}
      onClose={onClose}
      centered
      getContainer={document.body} // fixed bug in safari. when u close the modal page is scrolled down
    >
      <ModalTitle className={styles.modal_title}>
        <img src={fingerInCircle} alt='finger in circle' />
        <span>
          Изменить состояние курьера <br /> id: {editTransactionId}
        </span>
      </ModalTitle>
      <br />
      <form onSubmit={onSubmit}>
        <CustomSelect
          id={'transaction-statuses'}
          className={styles.net_select}
          placeholder='Выберите доступный статус'
          value={transactionStatusMapping[changedValue as keyof typeof transactionStatusMapping]}
          onChange={(value) => setChangedValue(value)}
          options={optionsMapper[selectedStatus as keyof typeof optionsMapper]?.map((item) => ({
            value: item,
            label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
          }))}
          error={false}
        />
        <Button className={styles.confirm_btn} onClick={() => onChange(changedValue)}>
          Подтвердить
        </Button>
      </form>
    </CustomModal>
  );
}
