import { ICommissionsListItem, TCommissionsType } from './types';
import { Form, InputRef, TableProps } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useAppDispatch } from 'app/GlobalRedux/store';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { commissionsApi } from '../api/commissionsApi';

export type DataIndex = keyof ICommissionsListItem;

type OnChange = NonNullable<TableProps<ICommissionsListItem>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useCommissionsTableLogic(type: TCommissionsType) {
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [commissionsList, setCommissionsList] = useState<ICommissionsListItem[]>([]);

  const [form] = Form.useForm();
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const queryParams = new URLSearchParams(location.search);

  const { showError } = useAppMessage();

  const rateFrom = queryParams.get('rateFrom') ?? undefined;
  const rateTo = queryParams.get('rateTo') ?? undefined;

  const [getCommissions, getCommissionsResponse] = commissionsApi.useLazyGetCommissionsQuery();
  const [commission, commissionResponse] = commissionsApi.usePutCommissionMutation();
  const [getCommissionsCount, getCommissionsCountResponse] = commissionsApi.useLazyGetCommissionsCountQuery();

  const isEditing = (record: ICommissionsListItem) => record.commissionId === editingKey;

  const edit = (record: ICommissionsListItem) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.commissionId);
  };

  const cancel = () => setEditingKey(null);

  const save = async (commissionId: number) => {
    try {
      const row: ICommissionsListItem = await form.validateFields();
      setEditingKey(null);

      const response = await commission({
        body: {
          commissionValue: row.commissionValue,
          commissionPercent: row.commissionPercent,
          minValue: row.minValue,
          maxValue: row.maxValue,
          isPercent: row.isPercent,
          isEnabled: row.isEnabled,
        },
        type,
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        commissionId: commissionId.toString() ?? 'frontend error', // rate_id  а надо commission_id
      }).unwrap();

      if (response) {
        setCommissionsList((prevList) => prevList.map((item) => (item.commissionId === commissionId ? { ...item, ...row } : item)));
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  const getCommissionsHandler = () => {
    getCommissions({
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      type,
      page: currentPage,
      perPage,
      rateFrom: rateFrom,
      rateTo: rateTo,
      rateId: params.id ? +params.id : undefined,
    });
    getCommissionsCount({
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      type,
      page: currentPage,
      perPage,
      rateFrom: rateFrom,
      rateTo: rateTo,
      rateId: params.id ? +params.id : undefined,
    });
  };

  useEffect(() => {
    dispatch(
      commissionsApi.endpoints.getCommissions.initiate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        type,
        page: currentPage,
        perPage,
        rateFrom: searchedColumn === 'rateFrom' ? searchText : undefined,
        rateTo: searchedColumn === 'rateTo' ? searchText : undefined,
      })
    );
  }, [searchedColumn, searchText]);

  useEffect(() => {
    if (getCommissionsResponse.isError || commissionResponse.isError)
      showError((getCommissionsResponse.error ?? commissionResponse.error) as IResponseError);
  }, [getCommissionsResponse.isError, getCommissionsResponse.error, commissionResponse.isError, commissionResponse.error]);

  useEffect(() => {
    if (getCommissionsResponse.data?.list) {
      setCommissionsList(getCommissionsResponse.data.list);
    }
  }, [getCommissionsResponse.data]);

  useEffect(() => {
    getCommissionsHandler();
  }, [currentPage, perPage]);

  return {
    searchInput,
    handleSearch,
    handleReset,
    searchedColumn,
    searchText,
    isEditing,
    edit,
    save,
    cancel,
    data: commissionsList,
    form,
    isLoading: getCommissionsResponse.isLoading,
    isFetching: getCommissionsResponse.isFetching,
    sortedInfo,
    handleChange,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    commissionsCounter: getCommissionsCountResponse.data?.total,
  };
}
