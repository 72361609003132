import { Menu, MenuProps } from 'antd';
import { Header as AntHeader } from 'antd/es/layout/layout';
import { useAuth } from 'entities/Auth';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../../../assets/icons/logo.svg';
import logout from '../../../../assets/icons/logout.svg';
import { IResponseError } from '../../../../shared/types/Interfaces/IResponseMessage';
import { useLazyGetUsersQuery } from '../../../Users';

import styles from './header.module.scss';

const items: MenuProps['items'] = [
  {
    label: (
      <NavLink to='wallet/users' className={({ isActive }) => (isActive ? styles.active : '')}>
        Delta Wallet
      </NavLink>
    ),
    key: 'wallet',
  },
  {
    label: (
      <NavLink to='#' className={({ isActive }) => (isActive ? styles.active : '')}>
        Delta Merchant
      </NavLink>
    ),
    key: 'merchant',
  },
];

export function Header() {
  const { signOut } = useAuth();
  const [testAuthStateRequest, testAuthStateResult] = useLazyGetUsersQuery();

  useEffect(() => {
    testAuthStateRequest({ panelType: 'wallet' });
  }, []);

  useEffect(() => {
    const status: number | undefined = (testAuthStateResult?.error as IResponseError)?.status;
    if (status === 403 || status === 401 || status === 400) signOut(401);
  }, [testAuthStateResult?.error]);

  return (
    <AntHeader className={styles.header}>
      <NavLink to='/' className={styles.logo}>
        <img src={logo} alt='logo' />
      </NavLink>

      <Menu mode='horizontal' defaultSelectedKeys={['1']} className={styles.menu} items={items} />

      <div className={styles.control}>
        <div className={styles.logout} onClick={() => signOut()}>
          <span>Выход</span>
          <img src={logout} alt='logout' />
        </div>
      </div>
    </AntHeader>
  );
}
