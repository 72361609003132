import { DatePicker, Descriptions, Flex } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { Dayjs } from 'dayjs';
import { Button } from 'shared/ui/Button/Button';
import { IPaymentCodes } from 'widgets/User';

import { usePaymentCodeCourierDescription } from '../../model/usePaymentCodeCourierDescription';

import styles from './paymentCodes.module.scss';

export function PaymentCodeCourierDescription({ codeInfo }: { codeInfo: IPaymentCodes }) {
  const logic = usePaymentCodeCourierDescription(codeInfo);

  return (
    <Descriptions title={`Курьер`} column={2} size='small' className={styles.description}>
      <Descriptions.Item label='Courier name'>{codeInfo.courier.name}</Descriptions.Item>
      <Descriptions.Item label='Courier address'>{codeInfo.courier.address}</Descriptions.Item>
      <Descriptions.Item label='Country name'>{codeInfo.courier.countryName}</Descriptions.Item>
      <Descriptions.Item label='City name'>{codeInfo.courier.cityName}</Descriptions.Item>
      <Descriptions.Item span={2}>
        <DatePicker
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              now: 'Current Time',
              ok: 'Изменить дату',
            },
          }}
          allowClear={false}
          inputReadOnly={true}
          format='YYYY-MM-DD HH:00'
          open={logic.isDataPickerOpened}
          value={logic.selectedCourierDate}
          disabledDate={logic.disabledDate}
          placeholder='Выберите дату'
          showTime={{ format: 'HH' }}
          className={styles.data_picker}
          popupClassName={styles.date_picker_popup}
          onChange={(date) => logic.setSelectedCourierDate(date)}
          getPopupContainer={(trigger: HTMLElement) => trigger.parentElement || document.body}
          renderExtraFooter={() => (
            <Flex justify='flex-end'>
              <Button loading={logic.putTransactionCourierResponse.isLoading} onClick={logic.confirmNewCourierDate}>
                Подтвердить
              </Button>
              &nbsp;
              <Button disabled={logic.putTransactionCourierResponse.isLoading} onClick={() => logic.setIsDataPickerOpened(false)}>
                Отмена
              </Button>
            </Flex>
          )}
        />
        &nbsp;
        <Button onClick={() => logic.setIsDataPickerOpened(true)}>Изменить дату</Button>
      </Descriptions.Item>
    </Descriptions>
  );
}
