import { IEditPayMethodParams, IPayMethod, payMethodApi } from 'entities/PayMethod';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export const usePayMethodLogic = () => {
  const { showError, showSuccess } = useAppMessage();
  const query = useParams();

  // Pay method state
  const [payMethod, setPayMethod] = useState<IPayMethod>();
  const [newDescription, setNewDescription] = useState<string>('');
  const [newIsEnabled, setNewIsEnabled] = useState<boolean | undefined>(payMethod?.isEnabled);
  const [newIsHidden, setNewIsHidden] = useState<boolean | undefined>(payMethod?.isHidden);
  const [newName, setNewName] = useState<string>('');

  // Pay method actions
  const [editPayMethodRequest, editPayMethodResult] = payMethodApi.useEditPayMethodMutation();
  const [getPayMethodRequest, getPayMethodResult] = payMethodApi.useLazyGetPayMethodQuery();

  const getPayMethod = async (payMethodId: string) => {
    try {
      const result = await getPayMethodRequest({ payMethodId }).unwrap();
      setPayMethod(result.data);
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const editPayMethod = async (params: Omit<IEditPayMethodParams, 'payMethodId'>) => {
    try {
      if (!payMethod?.payMethodId) return;
      const result = await editPayMethodRequest({ payMethodId: `${payMethod.payMethodId}`, ...params }).unwrap();
      setPayMethod(result.data);
      showSuccess('Платежный метод успешно обновлен');
    } catch (e) {
      showError(e as IResponseError);
    }
  };

  const handleNewNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleNewNameChangeSubmit = () => {
    setNewName('');
    editPayMethod({ name: newName });
  };

  const handleNewNameChangeCancel = () => {
    setNewName('');
  };

  const handleNewIsHiddenChange = () => {
    if (!payMethod) return;
    editPayMethod({ isHidden: !payMethod?.isHidden });
  };

  const handleNewIsEnabledChange = () => {
    if (!payMethod) return;
    editPayMethod({ isEnabled: !payMethod?.isEnabled });
  };

  const handleNewDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewDescription(e.target.value);
  };

  const handleNewDescriptionChangeSubmit = () => {
    setNewDescription('');
    editPayMethod({ description: newDescription });
  };

  const handleNewDescriptionChangeCancel = () => {
    setNewDescription('');
  };

  useEffect(() => {
    query?.payMethodId && getPayMethod(query.payMethodId);
  }, [query]);

  return {
    flow: {
      handleNewNameChange,
      handleNewIsHiddenChange,
      handleNewIsEnabledChange,
      handleNewNameChangeCancel,
      handleNewNameChangeSubmit,
      handleNewDescriptionChange,
      handleNewDescriptionChangeSubmit,
      handleNewDescriptionChangeCancel,
    },
    state: {
      newName,
      payMethod,
      newIsHidden,
      newIsEnabled,
      newDescription,
    },
  };
};

export type TUsePayMethodLogic = ReturnType<typeof usePayMethodLogic>;
