import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import {
  EWeekDaysMap,
  IOffice,
  IOfficeScheduleOnServer,
  IOfficeRequest,
  IOfficeResponse,
  TAddOfficeRequest,
  TPUTOfficeRequest,
} from '../model/types';

export const officesApi = createApi({
  reducerPath: 'officesApi',
  baseQuery,
  endpoints: (builder) => ({
    getOffices: builder.query<IOfficeResponse, IOfficeRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[cityId]': params.cityId,
          'filter[name]': params.name,
          'filter[state]': params.state,
        };

        return {
          url: `/wallet/offices/`,
          params: updatedParams,
        };
      },
    }),
    getOfficesCount: builder.query<{ total: number }, IOfficeRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[cityId]': params.cityId,
          'filter[name]': params.name,
          'filter[state]': params.state,
        };

        return {
          url: `/wallet/offices/count`,
          params: updatedParams,
        };
      },
    }),
    getOffice: builder.query<{ data: IOffice }, { officeId: string }>({
      query: ({ officeId }) => {
        return {
          url: `/wallet/offices/${officeId}`,
        };
      },
      transformResponse: (response: { data: IOffice }) => {
        const result = [];
        for (const key in response?.data.schedule) {
          const newItem = structuredClone(response.data.schedule[key]);
          newItem['weekDay'] = EWeekDaysMap[key as keyof typeof EWeekDaysMap];
          result.push(newItem);
        }

        response.data.schedule = result;

        return response;
      },
    }),
    putOffice: builder.mutation<{ data: IOffice }, { officeId: number; body: TPUTOfficeRequest | undefined }>({
      query: ({ officeId, body }) => {
        return {
          url: `/wallet/offices/${officeId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    addOffice: builder.mutation<IOfficeResponse, TAddOfficeRequest>({
      query: (body) => {
        return {
          url: `/wallet/offices`,
          method: 'POST',
          body,
        };
      },
    }),
    disableOffice: builder.mutation<{ data: IOffice }, { officeId: string }>({
      query: ({ officeId }) => {
        return {
          url: `/wallet/offices/${officeId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});
