import { Card, Flex, Form, Popconfirm, Table, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import Input from 'shared/ui/Input/Input';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';

import { countryPayMethodsTableColumns } from '../../model/consts/countryPayMethodsTableColumns';
import { countryStatusOptions } from '../../model/consts/countryStatusOptions';
import { useCountryLogic } from '../../model/hooks/useCountryLogic';
import { AddCountryPayMethodModal } from '../AddCountryPayMethodModal/AddCountryPayMethodModal';

import styles from './country.module.scss';

export const Country = () => {
  const { flow, state } = useCountryLogic();

  return (
    <Flex vertical gap={18}>
      <Card>
        <Flex align='center' justify='space-between'>
          <Flex vertical>
            <Typography.Title level={3} style={{ marginBottom: 3 }} children={state.country?.name} />
            <Typography.Text type='secondary' children='Страна' />
          </Flex>
          <Popconfirm
            title={`Вы уверены что хотите ${state.country?.isEnabled ? 'отключить' : 'включить'} страну?`}
            placement='leftTop'
            onConfirm={flow.handleCountryIsEnabledChange}
          >
            <Button>{state.country?.isEnabled ? 'Отключить страну' : 'Включить страну'}</Button>
          </Popconfirm>
        </Flex>

        <Form layout='vertical' className={styles.country_form}>
          <Flex align='flex-start' justify='flex-start' gap={21}>
            <FormItem label='Название'>
              <InputEditable
                name='name'
                value={state.countryName}
                onCancel={() => {}}
                onChange={flow.handleCountryNameChange}
                onConfirm={flow.handleCountryNameChangeSubmit}
                placeholder={state.country?.name}
              />
            </FormItem>

            <FormItem label='Код страны' name='countryCode'>
              <Input placeholder={state.country?.countryCode} value={state.country?.countryCode} readOnly />
            </FormItem>

            <FormItem label='Статус' name='isEnabled'>
              <Input
                value={state.country?.isEnabled ? 'Активна' : 'Не активна'}
                placeholder={state.country?.isEnabled ? 'Активна' : 'Не активна'}
                readOnly
              />
            </FormItem>
          </Flex>
        </Form>
      </Card>

      <Card>
        <Flex align='center' justify='space-between'>
          <Flex vertical>
            <Typography.Title level={3} style={{ marginBottom: 3 }} children='Платежные методы' />
            <Typography.Text type='secondary' children={`Найдено ${state.totalCountryPayMethods} методов для страны ${state.country?.name}`} />
          </Flex>
          <Button onClick={flow.handleOpenAddCountryPayMethodModal}>+ Добавить метод</Button>
        </Flex>

        <Table
          style={{ marginTop: '30px' }}
          scroll={{ x: 'scroll' }}
          rowKey={(row) => row.name}
          columns={[
            ...countryPayMethodsTableColumns,
            {
              key: 'btn',
              title: 'Действия',
              dataIndex: 'btn',
              render: (_, payMethod) => (
                <Popconfirm
                  title='Вы уверены что хотите удалить этот платежный метод?'
                  placement='topLeft'
                  onConfirm={() => flow.deleteCountryPayMethod(payMethod.payMethodId)}
                >
                  <Button>Удалить</Button>
                </Popconfirm>
              ),
            },
          ]}
          loading={state.isLoading}
          dataSource={state.countryPayMethods}
          pagination={false}
        />
      </Card>

      <AddCountryPayMethodModal logic={{ state, flow }} />
    </Flex>
  );
};
