import * as types from './adminUserApiTypes';
import { baseQuery } from 'shared/api';

import { createApi } from '@reduxjs/toolkit/query/react';

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query<types.IGetAdminUsersResponse, types.IGetAdminUsersRequest>({
      query: () => {
        return {
          url: 'users',
        };
      },
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery } = adminUserApi;
