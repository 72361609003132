import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import {
  EWeekDaysMap,
  ICourier,
  ICourierScheduleOnServer,
  ICouriersRequest,
  ICouriersResponse,
  TAddCourierRequest,
  TPUTCourierRequest,
} from '../model/types';

export const couriersApi = createApi({
  reducerPath: 'couriersApi',
  baseQuery,
  endpoints: (builder) => ({
    getCouriers: builder.query<ICouriersResponse, ICouriersRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[cityId]': params.cityId,
          'filter[name]': params.name,
          'filter[state]': params.state,
        };

        return {
          url: `/wallet/couriers/`,
          params: updatedParams,
        };
      },
    }),
    getCouriersCount: builder.query<{ total: number }, ICouriersRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[countryCode]': params.countryCode,
          'filter[cityId]': params.cityId,
          'filter[name]': params.name,
          'filter[state]': params.state,
        };

        return {
          url: `/wallet/couriers/count`,
          params: updatedParams,
        };
      },
    }),
    getCourier: builder.query<{ data: ICourier }, { courierId: string }>({
      query: ({ courierId }) => {
        return {
          url: `/wallet/couriers/${courierId}`,
        };
      },
      transformResponse: (response: { data: ICourier }) => {
        const result = [];
        for (const key in response?.data.schedule) {
          const newItem = structuredClone(response.data.schedule[key]);
          newItem['weekDay'] = EWeekDaysMap[key as keyof typeof EWeekDaysMap];
          result.push(newItem);
        }

        response.data.schedule = result;

        return response;
      },
    }),
    putCourier: builder.mutation<{ data: ICourier }, { courierId: number; body: TPUTCourierRequest | undefined }>({
      query: ({ courierId, body }) => {
        return {
          url: `/wallet/couriers/${courierId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    addCourier: builder.mutation<ICouriersResponse, TAddCourierRequest>({
      query: (body) => {
        return {
          url: `/wallet/couriers`,
          method: 'POST',
          body,
        };
      },
    }),
    disableCourier: builder.mutation<{ data: ICourier }, { courierId: string }>({
      query: ({ courierId }) => {
        return {
          url: `/wallet/couriers/${courierId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});
