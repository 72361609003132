import { Flex, Select, Typography } from 'antd';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import { finOpsOptions } from '../../model/consts/finOpsOptions';
import { TUseCountryLogic } from '../../model/hooks/useCountryLogic';

import styles from './AddCountryPayMethodModal.module.scss';

export interface AddCountryModalProps {
  logic: TUseCountryLogic;
}

export const AddCountryPayMethodModal: React.FC<AddCountryModalProps> = (props) => {
  const { state, flow } = props.logic;

  return (
    <CustomModal width={560} isOpen={state.isAddCountryPayMethodModalOpen} onClose={flow.handleOpenAddCountryPayMethodModal} centered>
      <ModalTitle>
        <span>Добавить платежный метод</span>
      </ModalTitle>

      <Flex vertical gap={24}>
        <Flex vertical gap={12}>
          <Flex vertical gap={6}>
            <Typography.Text children='Введите ID платежного метода' />
            <Input placeholder='...' name='payMethodId' type='number' onChange={flow.handleCountryPayMethodIdToAddChange} />
          </Flex>
          <Flex vertical gap={6}>
            <Typography.Text children='Выберите финансовые операции' />
            <Select
              mode='multiple'
              variant='filled'
              options={finOpsOptions}
              onChange={flow.handleFinOpsToAddChange}
              className={styles.finopsSelect}
              allowClear
              placeholder='...'
            />
          </Flex>
        </Flex>

        <Button block disabled={state.isAddCountryPayMethodBtnDisabled} onClick={flow.addCountryPayMethod}>
          Создать
        </Button>
      </Flex>
    </CustomModal>
  );
};
