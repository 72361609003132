import { FormEvent, useEffect, useState } from 'react';
import { transactionStatusMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';

import styles from './changeCourierStateWindow.module.scss';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChange: (value: string) => void;
  editCourierId: string | number | null;
  selectedState: string;
}

export function ChangeCourierStateWindow({ isOpened, onClose, onSubmit, onChange, editCourierId, selectedState }: Props) {
  const [changedValue, setChangedValue] = useState<any>();

  useEffect(() => {
    if (!isOpened) setChangedValue(undefined);
  }, [isOpened]);

  return (
    <CustomModal
      width={560}
      className={styles.modal}
      isOpen={isOpened}
      onClose={onClose}
      centered
      getContainer={document.body} // fixed bug in safari. when u close the modal page is scrolled down
    >
      <ModalTitle className={styles.modal_title}>
        <img src={fingerInCircle} alt='finger in circle' />
        <span>
          Изменить состояние курьера <br /> id: {editCourierId}
        </span>
      </ModalTitle>
      <br />
      <form onSubmit={onSubmit}>
        <CustomSelect
          id={'transaction-statuses'}
          className={styles.net_select}
          placeholder='Выберите состояние'
          // value={transactionStatusMapping[changedValue as keyof typeof transactionStatusMapping]}
          value={changedValue}
          onChange={(value) => setChangedValue(value)}
          options={[
            { label: 'активный', value: 'active' },
            { label: 'уволен', value: 'уволен' },
          ]}
          // options={optionsMapper[editCourierId as keyof typeof optionsMapper]?.map((item) => ({
          //   value: item,
          //   label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
          // }))}
          error={false}
        />
        <Button className={styles.confirm_btn} onClick={() => onChange(changedValue)}>
          Подтвердить
        </Button>
      </form>
    </CustomModal>
  );
}
