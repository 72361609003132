import { RootState } from 'app/GlobalRedux/store';
import { IComment } from 'entities/Comment';

export const getComments = (state: RootState): IComment[] => state.comments.comments;

export const getCommentsTotal = (state: RootState): number => state.comments.total;

export const getFilterAuthorId = (state: RootState): string | undefined => state.comments.filterAuthorId;

export const getFilterUpdatedAt = (state: RootState): 'ASC' | 'DESC' | undefined => state.comments.filterUpdatedAt;

export const getFilterIsEnabled = (state: RootState): boolean | undefined => state.comments.filterIsEnabled;
