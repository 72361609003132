import { EWeekDaysMap, IOffice, IOfficeScheduleItem, IOfficeScheduleOnServer, officesApi, TPUTOfficeRequest } from 'features/OfficesTable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export function useOfficeScheduleLogic() {
  const { id } = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [officeData, setOfficeData] = useState<IOffice | undefined>();

  const [putOffice] = officesApi.usePutOfficeMutation();
  const { data, isLoading, isFetching } = officesApi.useGetOfficeQuery({
    officeId: id ?? 'frontend error',
  });

  const saveSchedule = async () => {
    if (!officeData) return;

    const newCourierData = structuredClone(officeData);

    function getEnumKeyByValue(value: IOfficeScheduleItem['weekDay']): keyof EWeekDaysMap {
      return (
        (Object.keys(EWeekDaysMap).find((key) => EWeekDaysMap[key as keyof typeof EWeekDaysMap] === value) as keyof EWeekDaysMap) ?? 'monday'
      );
    }

    const transformScheduleForServer = newCourierData.schedule.reduce((acc: any, curr: any) => {
      acc[getEnumKeyByValue(curr.weekDay)] = {
        begin: curr.begin,
        end: curr.end,
        pause: curr.pause,
      };

      return acc;
    }, {} as IOfficeScheduleOnServer);

    newCourierData.schedule = transformScheduleForServer;

    try {
      await putOffice({
        body: newCourierData as TPUTOfficeRequest,
        officeId: id ? +id : 0,
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const changeScheduleTime = (value: string | undefined, index: number, type: 'begin' | 'end') => {
    if (!officeData) return;

    const newData = structuredClone(officeData);

    if (!newData.schedule) return;

    newData.schedule[index][type] = value;

    setOfficeData(newData);
  };

  const deleteBreak = (itemIndex: number, removeIndex: number) => {
    if (!officeData) return;

    const newData = structuredClone(officeData);

    if (!newData.schedule) return;

    newData.schedule[itemIndex].pause?.splice(removeIndex, 1);

    setOfficeData(newData);
  };

  const changeBreak = (weekDayIndex: number, pauseIndex: number, value: { begin: string | null; end: string | null }) => {
    if (!officeData) return;

    const newData = structuredClone(officeData);

    if (!newData.schedule || !newData.schedule[weekDayIndex].pause) return;

    newData.schedule[weekDayIndex].pause[pauseIndex] = value;

    setOfficeData(newData);
  };

  const addNewBreak = (weekDayIndex: number) => {
    if (!officeData) return;

    const newData = structuredClone(officeData);

    if (!newData.schedule) return;

    if (newData.schedule[weekDayIndex].pause) {
      newData.schedule[weekDayIndex].pause.push({ begin: null, end: null });
    } else {
      newData.schedule[weekDayIndex].pause = [{ begin: null, end: null }];
    }

    setOfficeData(newData);
  };

  useEffect(() => {
    if (data?.data) setOfficeData(data.data);
  }, [data?.data]);

  return { changeScheduleTime, changeBreak, deleteBreak, addNewBreak, officeData, saveSchedule };
}
