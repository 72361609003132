import { Card, Divider, Flex, Popconfirm, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Input from 'shared/ui/Input/Input';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';

import { Button } from '../../../../shared/ui/Button/Button';
import { usePayMethodLogic } from '../../model/hooks/usePayMethodLogic';

import styles from './payMethod.module.scss';

export const PayMethod = () => {
  const { flow, state } = usePayMethodLogic();

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Card style={{ marginBottom: 21 }}>
        <Flex align='center' justify='space-between'>
          <Flex vertical>
            <Typography.Title level={3} style={{ marginBottom: 3 }} children={`${state.payMethod?.name} (${state.payMethod?.systemName})`} />
            <Typography.Text type='secondary' children={`Платежный метод`} />
          </Flex>
          <Flex align='center'>
            <Popconfirm
              title={`Вы уверены что хотите ${state.payMethod?.isEnabled ? 'отключить' : 'включить'} этот метод?`}
              placement='leftTop'
              onConfirm={flow.handleNewIsEnabledChange}
            >
              <Button>{state.payMethod?.isEnabled ? 'Отключить метод' : 'Включить метод'}</Button>
            </Popconfirm>
            <Divider type='vertical' />
            <Popconfirm
              title={`Вы уверены что хотите ${state.payMethod?.isHidden ? 'сделать видимым' : 'скрыть'} этот метод?`}
              placement='leftTop'
              onConfirm={flow.handleNewIsHiddenChange}
            >
              <Button>{state.payMethod?.isHidden ? 'Сделать видимым' : 'Скрыть'}</Button>
            </Popconfirm>
          </Flex>
        </Flex>

        <Divider />

        <Flex gap={21} align='flex-start'>
          <Flex vertical gap={9}>
            <Typography.Text children='Название' />
            <InputEditable
              name='name'
              value={state.newName}
              onChange={flow.handleNewNameChange}
              onCancel={flow.handleNewNameChangeCancel}
              onConfirm={flow.handleNewNameChangeSubmit}
              placeholder={state.payMethod?.name ?? '-'}
            />
          </Flex>

          <Flex vertical gap={9}>
            <Typography.Text children='Описание' />
            <InputEditable
              name='description'
              value={state.newDescription}
              onChange={flow.handleNewDescriptionChange}
              onCancel={flow.handleNewDescriptionChangeCancel}
              onConfirm={flow.handleNewDescriptionChangeSubmit}
              placeholder={state.payMethod?.description ?? '-'}
            />
          </Flex>

          <Flex vertical gap={9}>
            <Typography.Text children='Системное название' />
            <Input name='systemName' value={state.payMethod?.systemName} readOnly />
          </Flex>

          <Flex vertical gap={9}>
            <Typography.Text children='Статус' />
            <Input name='isEnabled' value={state.payMethod?.isEnabled ? 'Активен' : 'Не активен'} readOnly />
          </Flex>

          <Flex vertical gap={9}>
            <Typography.Text children='Отображение' />
            <Input name='isHidden' value={state.payMethod?.isHidden ? 'Скрыт' : 'Виден'} readOnly />
          </Flex>
        </Flex>
      </Card>

      <div className={styles.control_btns}>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
    </React.Fragment>
  );
};
