import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { createContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { IBank } from '../../model/types';
import { TBanksTableLogic, useBanksTableLogic } from '../../model/useBanksTableLogic';
import { Filters } from '../Filters/Filters';

export const BanksTableContext = createContext<TBanksTableLogic | null>(null);

export function BanksTable() {
  const logic = useBanksTableLogic();

  const columns: ColumnsType<IBank> = [
    {
      title: 'UID',
      dataIndex: 'bankId',
      key: 'bankId',
    },
    {
      title: 'Создано',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => transformDateToUserFriendly(date),
    },
    {
      title: 'Название',
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: 'Страна',
      dataIndex: 'countryCode',
      key: 'countryCode',
    },
    {
      title: 'Платежная система',
      dataIndex: 'paySystem',
      key: 'paySystem',
      render: (value) => value.join(', '),
    },
    {
      title: 'Активен',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (value) => String(value),
    },

    {
      title: 'Подробнее',
      dataIndex: 'more',
      key: 'more',
      width: '100px',
      render: (_, record) => {
        return (
          <NavLink to={`${window.location.pathname}/${record.bankId}`}>
            <Button>Открыть</Button>
          </NavLink>
        );
      },
    },
  ];

  return (
    <BanksTableContext.Provider value={logic}>
      <Filters />

      <Table
        rowKey={(row) => row.bankId}
        columns={columns}
        dataSource={logic.data?.list}
        loading={logic.isLoading || logic.isFetching}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          total: logic.transactionsCount,
          showTotal: (value) => `Всего банков: ${value}`,
          locale: { items_per_page: 'на стр.' },
        }}
      />
    </BanksTableContext.Provider>
  );
}
