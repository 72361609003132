import { Card, Checkbox, Divider, Flex, Typography } from 'antd';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

import { TUseCommentsLogic, useCommentsLogic } from '../../model/hooks/useCommentsLogic';
import { Comment } from '../Comment/Comment';
import { NewComment } from '../NewComment/NewComment';

import styles from './comments.module.scss';

export const Comments = () => {
  const { flow, state }: TUseCommentsLogic = useCommentsLogic();

  return (
    <Card className={styles.comments_card}>
      <Flex vertical gap={15}>
        <Typography.Text>Найдено {state.totalCommentsCount} комментариев</Typography.Text>

        <Flex align='center' gap={15}>
          <CustomSelect
            error={false}
            value={state.filterAuthorId}
            options={flow.getFilterAuthorIdOptions()}
            onChange={flow.handleFilterAuthorIdChange}
            className={styles.comments_card_input}
            placeholder='ID автора'
          />
          <CustomSelect
            error={false}
            value={state.filterUpdatedAt}
            options={flow.getFilterUpdatedAtOptions()}
            onChange={flow.handleFilterUpdatedAtChange}
            className={styles.comments_card_input}
            placeholder='Сортировать по'
          />
          <Button onClick={flow.handleIsNewCommentChange}>
            <Typography.Text>{state.isNewComment ? 'Отмена' : 'Добавить комментарий +'}</Typography.Text>
          </Button>
        </Flex>

        <Flex align='center' gap={12}>
          <Typography.Text>Показать удаленные комментарии</Typography.Text>
          <Checkbox checked={!state.filterIsEnabled} defaultChecked onChange={(e) => flow.handleFilterIsEnabledChange(!e.target.checked)} />
        </Flex>

        <Divider className={styles.comments_card_divider} />

        {state.isNewComment && <NewComment logic={{ flow, state }} />}

        {state.comments.map((comment) => (
          <Comment key={comment.userCommentId} comment={comment} />
        ))}
      </Flex>
    </Card>
  );
};
