import { Card, DatePicker, Divider, Flex, Form, Input, Segmented, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { Button } from 'shared/ui/Button/Button';

import { countryStatusOptions } from '../../model/consts/countryStatusOptions';
import { TUseCountriesLogic } from '../../model/hooks/useCountriesLogic';

import styles from './CountriesFilters.module.scss';

export interface CountriesFiltersProps {
  logic: TUseCountriesLogic;
}

export const CountriesFilters: React.FC<CountriesFiltersProps> = (props) => {
  const { state, flow } = props.logic;

  return (
    <Card>
      <Flex align='center' justify='space-between'>
        <Flex vertical>
          <Typography.Title level={3} style={{ marginBottom: 3 }} children='Страны' />
          <Typography.Text type='secondary' children='Номенклатура' />
        </Flex>
        <Button onClick={flow.handleOpenAddCountryModal}>Добавить страну +</Button>
      </Flex>

      <Divider />

      <Typography.Title level={3} style={{ marginBottom: 21 }} children='Фильтры' />

      <Form form={state.form} layout='vertical'>
        <Flex gap={21}>
          <FormItem label='Название страны' name='name' className={styles.form_item}>
            <Input placeholder='Введите название страны' />
          </FormItem>

          <FormItem label='Добавлена с' name='createdAt' className={styles.form_item}>
            <DatePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' placeholder='Выберите дату' />
          </FormItem>

          <FormItem label='Статус' name='isEnabled'>
            <Segmented defaultValue={undefined} options={countryStatusOptions} />
          </FormItem>
        </Flex>
      </Form>

      <Flex gap={10} align='center' justify='flex-end'>
        <Flex gap={10} justify='flex-end'>
          <Button onClick={flow.handleApplyFilters} disabled={state.isSubmitBtnDisabled}>
            Применить
          </Button>
          <Button onClick={flow.handleResetFilters}>Отменить</Button>
        </Flex>
      </Flex>
    </Card>
  );
};
