import { Image } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { useAppMessage } from 'utils/messageContext';
import { userApi } from 'widgets/User';

export function GetKYCPhotoBtn() {
  const { showError } = useAppMessage();
  const location = useLocation();
  const params = useParams();

  const [getKYCPhotoRequest, getKYCPhotoResponse] = userApi.useLazyGetKYCPhotoQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = userApi.useGetUserQuery({
    userId: params.id ?? 'frontend error',
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  useEffect(() => {
    if (getKYCPhotoResponse.isError) showError({ data: { message: 'Изображение не пришло' }, status: 500 });
    if (getKYCPhotoResponse.isSuccess) setIsModalOpen(true);
  }, [getKYCPhotoResponse]);

  return (
    <>
      <Button
        onClick={() =>
          getKYCPhotoRequest({
            userId: params.id ?? 'frontend error',
            panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
          })
        }
        loading={getKYCPhotoResponse.isLoading || getKYCPhotoResponse.isFetching}
      >
        Посмотреть фото
      </Button>

      <CustomModal width={560} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} getContainer={document.body} centered>
        <ModalTitle>
          <span>Фото {data?.data?.surname}</span>
        </ModalTitle>

        <br />
        <Image src={getKYCPhotoResponse.data} alt='document photo' />
      </CustomModal>
    </>
  );
}
