import { Table, TimePicker } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { ICourier, ICourierScheduleItem } from 'features/CouriersTable';
import React, { ReactElement } from 'react';
import { Button } from 'shared/ui/Button/Button';

import * as AntdIcons from '@ant-design/icons';

import { useCourierScheduleLogic } from '../../model/useCourierScheduleLogic';

import styles from './courierSchedule.module.scss';

const format = 'HH:mm';

export function CourierSchedule() {
  const { changeScheduleTime, changeBreak, deleteBreak, addNewBreak, courierData, saveSchedule } = useCourierScheduleLogic();

  const columns: ColumnsType<ICourierScheduleItem> = [
    {
      title: 'День недели',
      dataIndex: 'weekDay',
      key: 'weekDay',
      width: '150px',
    },
    {
      title: 'Начало',
      dataIndex: 'begin',
      key: 'begin',
      width: '150px',
      render: (date, data, weekDayIndex) => (
        <TimePicker
          format={format}
          value={dayjs(date, format)}
          onOk={(e) => changeScheduleTime(e.format(format), weekDayIndex, 'begin')}
          showNow={false}
        />
      ),
    },
    {
      title: 'Конец',
      dataIndex: 'end',
      key: 'end',
      width: '150px',
      render: (date, data, weekDayIndex) => (
        <TimePicker
          format={format}
          value={dayjs(date, format)}
          onOk={(e) => changeScheduleTime(e.format(format), weekDayIndex, 'end')}
          showNow={false}
        />
      ),
    },
    {
      title: 'Перерыв',
      dataIndex: 'pause',
      key: 'pause',
      render: (pause: ICourierScheduleItem['pause'], data, weekDayIndex) =>
        pause?.reduce((acc, curr, pauseIndex) => {
          acc.push(
            <React.Fragment key={Math.random() * 100}>
              <TimePicker.RangePicker
                style={{ width: '150px' }}
                value={[curr.begin ? dayjs(curr.begin, format) : undefined, curr.end ? dayjs(curr.end, format) : undefined]}
                format={format}
                onOk={(e) => {
                  changeBreak(weekDayIndex, pauseIndex, { begin: e[0]?.format(format) ?? null, end: e[1]?.format(format) ?? null });
                }}
              />
              <AntdIcons.DeleteTwoTone
                className={styles.control_btn}
                onClick={() => deleteBreak(weekDayIndex, pauseIndex)}
                twoToneColor={'red'}
              />
            </React.Fragment>
          );
          return acc;
        }, [] as ReactElement[]),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      width: '200px',
      fixed: 'right',
      render: (_, data, weekDayIndex) => {
        return <Button onClick={() => addNewBreak(weekDayIndex)}>Добавить перерыв</Button>;
      },
    },
  ];

  return (
    <>
      <Table rowKey={(row) => row.weekDay} scroll={{ x: 1000 }} pagination={false} columns={columns} dataSource={courierData?.schedule} />

      <Button className={styles.save_schedule} onClick={saveSchedule}>
        Сохранить расписание
      </Button>
    </>
  );
}
